<div class="content-wrapper" [ngClass]="{ 'content-wrapper-spacing': router.url === '/canvas-crate'}">
    <!------------------------------------ Main Banner------------------------------------>
    <div class="dashboard-main-banner d-flex flex-row">
        <div class="side-img-wrap align-items-center">
            <img class="side-img w-auto" src="{{cdnUrl}}/assets/images/brand-account-images/image6-fliped.png"
                 style="margin-left: 20px">
        </div>
        <div>
            <h1>Canvas Crate</h1>
            <p>All things canvas crate</p>
        </div>
    </div>

    <section class="content">
        <div class="pr-0">
            <div class="mx-md-5 pr-0 ml-0">
                <section class="pr-0">

                    <!------------------------------------------- Navigartion Tabs ---------------------------------->
                    <nav>
                        <div class="nav nav-tabs " id="nav-tab" role="tablist">
                            <a #poplink1 id="poplink1" (click)="showTabContent(pop1,poplink1)"
                               class="nav-item nav-link bs-tab-link active" href="javascript:void(0)">Future Crate</a>
                            <a #poplink2 id="poplink2" (click)="showTabContent(pop2, poplink2)" class="nav-item nav-link bs-tab-link"
                               href="javascript:void(0)">Current Crate</a>
                            <a #poplink3 id="poplink3" (click)="showTabContent(pop3, poplink3)" class="nav-item nav-link bs-tab-link"
                               href="javascript:void(0)">Past Crates</a>
                        </div>
                    </nav>

                    <!------------------------------------------- Tab Sections ---------------------------------->

                    <div class="tab-content pr-0 pr-md-5" id="nav-tabContent">

                        <!---------------------- Future Crates / New Crate  ---------------------->
                        <div #pop1 aria-labelledby="pop1-tab" class="tab-pane fade bs-tab-content show active" id="pop1" role="tabpanel">
                            <div class="pt-3"></div>
                            <div class="display-crates">

                                <!------------------ Future Crates If exists  ---------------------->
                                <ng-container *ngFor="let crate of future_crates; let i = index">
                                        <div class="mt-3">

                                            <ng-container *ngIf="i !== 0">
                                                <div class="crate_divider"></div>
                                            </ng-container>
                            
                                            <!-- Crate stats -->
                                            <div style="padding-bottom: 1rem;">
                                                <div class="row">
                                                    <div class="col-lg-2 col-md-2 col-sm-4 col-4 stats-frame">
                                                        <h6># Of Units</h6>
                                                        <small>{{crate.total_units}}</small>
                                                    </div>
                                                    <div class="col-lg-2 col-md-2 col-sm-4 col-4 stats-frame">
                                                        <h6>Crate start date</h6>
                                                        <small>{{ (crate.billing_start  | date:'MM/dd/yyyy') || 'Not Live' }}</small>
                                                    </div>
                                                    <div class="col-lg-2 col-md-2 col-sm-4 col-4 stats-frame">
                                                        <h6>Crate end date</h6>
                                                        <small>{{ (crate.billing_end | date:'MM/dd/yyyy') || 'Not Live' }}</small>
                                                    </div>
                                                </div>
                                            </div>
                            
                                            <div class="cc_info_container">
                                                <!-- Crate info -->
                                                <div class="form-group">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-md-6 col-sm-12 cc_info_box">
                                                            <p>Product Name</p>
                                                            <input class="form-control cc_input_fields" id="productName" [(ngModel)]="crate.product_name" >
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12 cc_info_box">
                                                            <p>Product URL</p>
                                                            <input class="form-control cc_input_fields" id="productName" [(ngModel)]="crate.product_url" >
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-md-6 col-sm-12 cc_info_box">
                                                            <p>Coupon Code for Canvas Crate Units:</p>
                                                            <input class="form-control cc_input_fields" id="crate_promo_code" [(ngModel)]="crate.crate_promo_code" >
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12 cc_info_box">
                                                            <p>Exclusive Coupon Code for Consumer Retargeting:</p>
                                                            <input class="form-control cc_input_fields" id="exclusive_code" [(ngModel)]="crate.exclusive_code" >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                            
                                            <!-- Crate media -->
                                            <div class="cc_media_column">
                            
                                                <div class="cc_video_section">
                            
                                                    <div class="media_label">
                                                        <div class="label">Matched Video</div>
                                                        <div class="suggestion">Suggested Length: 60s or less</div>
                                                    </div>
                            
                                                    <ng-container *ngIf="crate.video_media[0]; else videoPreview1">
                                                        <div (click)="fileInput1.click()" class="delete-icon-large-tile" style="top: 46px">
                                                            <img alt="edit" loading="lazy" src="assets/images/icons/cc_bin.png">
                                                            <input #fileInput1 (click)="deleteFile(crate.id, crate.video_media[0].id)" style="display:none;"/>
                                                        </div>
                                                        <!-- <video src="https://cdn.tailorie.com/media/uploads/users/6344/posts/Recommendation/1681183734.2348454-original.mp4" controls preload="metadata" class="video_preview"></video> -->
                                                        <video [src]="crate.video_media[0].media" controls preload="metadata" class="video_preview"></video>
                                                    </ng-container>
                            
                                                    <ng-template #videoPreview1>
                                                        <div appDrag (files)="directUpload(crate.id, $event)" (click)="fileInput1.click()" style="cursor: pointer;">
                                                            <input #fileInput1 (change)="directUpload(crate.id, $event)"
                                                                accept="video/*" style="display:none;" type="file"/>
                                                            <div class="upload_box_large video_upload_box">
                                                                <img alt="cloud" style="width: 36px;" loading="lazy" src="assets/images/icons/orange_cloud.png">
                                                                <div class="upload_text_large">Drag & Drop or <br><span class="orange">Choose File</span> to upload</div>
                                                                <div class="file_info">Aspect Ratio 9:16 MP4 or MOV<br />Max file size<br />250 MB</div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </div>
                            
                                                <div class="cc_notification_section">
                            
                                                    <div class="media_label">
                                                        <div class="label">Match Notification Image</div>
                                                    </div>
                            
                                                    <ng-container *ngIf="crate.notification_media[0]; else imagePreview0">
                                                        <div (click)="fileInput2.click()" class="delete-icon-large-tile notification_sec">
                                                            <img alt="edit" loading="lazy" src="assets/images/icons/cc_bin.png">
                                                            <input #fileInput2 (click)="deleteFile(crate.id, crate.notification_media[0].id)" style="display:none;"/>
                                                        </div>
                                                        <!-- <img src="https://cdn.tailorie.com/media/canvas_crate_media/Untitled_design-comp-1_9Xftdin.png" class="notification_preview"> -->
                                                        <img [src]="crate.notification_media[0].media" alt="" class="notification_preview">
                                                    </ng-container>
                                                    <ng-template #imagePreview0>
                                                        <div appDrag (files)="saveFiles(crate.id, [$event[0].file], 2)" (click)="fileInput2.click()" style="cursor: pointer;">
                                                            <input #fileInput2 (change)="saveFiles(crate.id, $event.target.files, 2)"
                                                                accept=".png, .jpg" style="display:none;" type="file"/>
                                                            <div class="upload_box_large notification_upload_box">
                                                                <img alt="cloud" style="width: 36px;" loading="lazy" src="assets/images/icons/orange_cloud.png">
                                                                <div class="upload_text_large">Drag & Drop or <br><span class="orange">Choose File</span> to upload</div>
                                                                <div class="file_info">Aspect Ratio 1:1 JPG or PNG<br />Max file size 2 MB</div>
                                                            </div>
                                                        </div>
                                                        </ng-template>
                                                </div>
                            
                                                <!-- 4 small images -->
                            
                                                <div class="other_images_section">
                            
                                                    <div class="media_label">
                                                        <div class="label">Product Images</div>
                                                        <div class="suggestion">Suggested: 3-4 Images</div>
                                                    </div>
                            
                                                    <div class="other_images_board">
                            
                                                        <!-- 1 image -->
                                                        <div class="image_couple">
                                                            <div class="small_image_section" appDrag (files)="saveFiles(crate.id, [$event[0].file], 3)">
                                                                <ng-container *ngIf="crate.image_media[0]; else imagePreview1">
                                                                    <div (click)="fileInput3.click()" class="delete-icon-small-tile">
                                                                        <img alt="edit" loading="lazy" src="assets/images/icons/cc_bin.png">
                                                                        <input #fileInput3 (click)="deleteFile(crate.id, crate.image_media[0].id)" style="display:none;"/>
                                                                    </div>
                                                                    <!-- <img src="https://cdn.tailorie.com/media/canvas_crate_media/9x16__1-comp-2.png" class="small_image_preview"> -->
                                                                    <img [src]="crate.image_media[0].media" alt="" class="small_image_preview">
                                                                </ng-container>
                                                                <ng-template #imagePreview1>
                                                                    <div (click)="fileInput3.click()" style="cursor: pointer;" class="small_upload_section">
                                                                        <input #fileInput3 (change)="saveFiles(crate.id, $event.target.files, 3)"
                                                                            accept=".png, .jpg" style="display:none;" type="file"/>
                                                                        <img alt="cloud" style="width: 24px;" loading="lazy" src="assets/images/icons/orange_cloud.png">
                                                                        <div class="upload_text_small">Drag & Drop or <br><span class="orange">Choose File</span> to upload</div>
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                            <div class="small_image_section" appDrag (files)="saveFiles(crate.id, [$event[0].file], 4)">
                                                                <ng-container *ngIf="crate.image_media[1]; else imagePreview2">
                                                                    <div (click)="fileInput4.click()" class="delete-icon-small-tile">
                                                                        <img alt="edit" loading="lazy" src="assets/images/icons/cc_bin.png">
                                                                        <input #fileInput4 (click)="deleteFile(crate.id, crate.image_media[1].id)" style="display:none;"/>
                                                                    </div>
                                                                    <!-- <img src="https://cdn.tailorie.com/media/canvas_crate_media/9x16__1-comp-2.png" class="small_image_preview"> -->
                                                                    <img [src]="crate.image_media[1].media" alt="" class="small_image_preview">
                                                                </ng-container>
                                                                <ng-template #imagePreview2>
                                                                    <div (click)="fileInput4.click()" style="cursor: pointer;" class="small_upload_section">
                                                                        <input #fileInput4 (change)="saveFiles(crate.id, $event.target.files, 4)"
                                                                            accept=".png, .jpg" style="display:none;" type="file"/>
                                                                        <img alt="cloud" style="width: 24px;" loading="lazy" src="assets/images/icons/orange_cloud.png">
                                                                        <div class="upload_text_small">Drag & Drop or <br><span class="orange">Choose File</span> to upload</div>
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="image_couple">
                                                            <div class="small_image_section" appDrag (files)="saveFiles(crate.id, [$event[0].file], 5)">
                                                                <ng-container *ngIf="crate.image_media[2]; else imagePreview3">
                                                                    <div (click)="fileInput5.click()" class="delete-icon-small-tile">
                                                                        <img alt="edit" loading="lazy" src="assets/images/icons/cc_bin.png">
                                                                        <input #fileInput5 (click)="deleteFile(crate.id, crate.image_media[2].id)" style="display:none;"/>
                                                                    </div>
                                                                    <img [src]="crate.image_media[2].media" alt="" class="small_image_preview">
                                                                </ng-container>
                                                                <ng-template #imagePreview3>
                                                                    <div (click)="fileInput5.click()" style="cursor: pointer;" class="small_upload_section">
                                                                        <input #fileInput5 (change)="saveFiles(crate.id, $event.target.files, 5)"
                                                                            accept=".png, .jpg" style="display:none;" type="file"/>
                                                                        <img alt="cloud" style="width: 24px;" loading="lazy" src="assets/images/icons/orange_cloud.png">
                                                                        <div class="upload_text_small">Drag & Drop or <br><span class="orange">Choose File</span> to upload</div>
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                            <div class="small_image_section" appDrag (files)="saveFiles(crate.id, [$event[0].file], 6)">
                                                                <ng-container *ngIf="crate.image_media[3]; else imagePreview4">
                                                                    <div (click)="fileInput6.click()" class="delete-icon-small-tile">
                                                                        <img alt="edit" loading="lazy" src="assets/images/icons/cc_bin.png">
                                                                        <input #fileInput6 (click)="deleteFile(crate.id, crate.image_media[3].id)" style="display:none;"/>
                                                                    </div>
                                                                    <img [src]="crate.image_media[3].media" alt="" class="small_image_preview">
                                                                </ng-container>
                                                                <ng-template #imagePreview4>
                                                                    <div (click)="fileInput6.click()" style="cursor: pointer;" class="small_upload_section">
                                                                        <input #fileInput6 (change)="saveFiles(crate.id, $event.target.files, 6)"
                                                                            accept=".png, .jpg" style="display:none;" type="file"/>
                                                                        <img alt="cloud" style="width: 24px;" loading="lazy" src="assets/images/icons/orange_cloud.png">
                                                                        <div class="upload_text_small">Drag & Drop or <br><span class="orange">Choose File</span> to upload</div>
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                            
                                            <!-- Crate description -->
                                            <div class="product_description">
                                                <div class="label">Product Description</div>
                                                <textarea [(ngModel)]="crate.product_description"
                                                        [ngClass]=""
                                                        class="form-control description_box"
                                                        ></textarea>
                                                <label class="description-character-count" style="padding-top: 10px;">
                                                    {{crate.product_description.length || 0}}/500 Characters
                                                </label>
                                            </div>
                            
                                            <ng-container *ngIf="crate.is_active">
                                                <div class="form-group float-right">
                                                    <button (click)="updateCanvasCrateForm(crate)" class="btn7 border-0 save_button">
                                                        <span *ngIf="!isLoading" class="save_text">Save changes</span>
                                                        <span *ngIf="isLoading" class="tiny-loader"></span>
                                                    </button>
                                                </div>
                                            </ng-container>
                            
                                        </div>
                                </ng-container>

                                <!------------------ Create Future Crate  ---------------------->
                                <!-- Crate stats -->
                                <ng-container *ngIf="future_crates.length == 0">
                                    <div class="mt-3">
                                        <div style="padding-bottom: 1rem;">
                                            <div class="row">
                                                <div class="col-lg-2 col-md-2 col-sm-4 col-4 stats-frame">
                                                    <h6># Of Units</h6>
                                                    <small>-</small>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-4 col-4 stats-frame">
                                                    <h6>Crate start date</h6>
                                                    <small>     -     </small>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-4 col-4 stats-frame">
                                                    <h6>Crate end date</h6>
                                                    <small>     -     </small>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="cc_info_container">
                                            <!-- Crate info -->
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 col-sm-12 cc_info_box">
                                                        <p>Product Name</p>
                                                        <input class="form-control cc_input_fields" id="productName" [(ngModel)]="newCrateData.product_name" >
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-12 cc_info_box">
                                                        <p>Product URL</p>
                                                        <input class="form-control cc_input_fields" id="productName" [(ngModel)]="newCrateData.product_url" >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 col-sm-12 cc_info_box">
                                                        <p>Coupon Code for Canvas Crate Units:</p>
                                                        <input class="form-control cc_input_fields" id="crate_promo_code" [(ngModel)]="newCrateData.crate_promo_code" >
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-12 cc_info_box">
                                                        <p>Exclusive Coupon Code for Consumer Retargeting:</p>
                                                        <input class="form-control cc_input_fields" id="exclusive_code" [(ngModel)]="newCrateData.exclusive_code" >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Crate media -->

                                        <!-- Crate description -->
                                        <div class="product_description">
                                            <div class="label">Product Description</div>
                                            <textarea [(ngModel)]="newCrateData.product_description"
                                                    [ngClass]=""
                                                    class="form-control description_box"
                                                    ></textarea>
                                            <label class="description-character-count" style="padding-top: 10px;">
                                                {{newCrateData.product_description.length || 0}}/500 Characters
                                            </label>
                                        </div>
                                        <div class="form-group float-right">
                                            <button (click)="CreateCanvasCrateForm(newCrateData)" class="btn7 border-0 save_button">
                                                <span *ngIf="!isLoading" class="save_text">Save changes</span>
                                                <span *ngIf="isLoading" class="tiny-loader"></span>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>

                        </div>

                        <!---------------------- Current Active Crates  ---------------------->
                        <div #pop2 aria-labelledby="pop2-tab" class="tab-pane fade bs-tab-content" id="pop2" role="tabpanel">
                            <div class="pt-3"></div>

                            <div class="display-crates">
                                <ng-container *ngFor="let crate of active_crates; let i = index">
                                    <div class="mt-3">
                                        
                                        <ng-container *ngIf="i !== 0">
                                            <div class="crate_divider"></div>
                                        </ng-container>
                        
                                        <!-- Crate stats -->
                                        <div style="padding-bottom: 1rem;">
                                            <div class="row">
                                                <div class="col-lg-2 col-md-2 col-sm-4 col-4 stats-frame">
                                                    <h6># Of Units</h6>
                                                    <small>{{crate.total_units}}</small>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-4 col-4 stats-frame">
                                                    <h6>Crate start date</h6>
                                                    <small>{{ (crate.billing_start  | date:'MM/dd/yyyy') || 'Not Live' }}</small>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-4 col-4 stats-frame">
                                                    <h6>Crate end date</h6>
                                                    <small>{{ (crate.billing_end | date:'MM/dd/yyyy') || 'Not Live' }}</small>
                                                </div>
                                            </div>
                                        </div>
                        
                                        <div class="cc_info_container">
                                            <!-- Crate info -->
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 col-sm-12 cc_info_box">
                                                        <p>Product Name</p>
                                                        <input class="form-control cc_input_fields" id="productName" [(ngModel)]="crate.product_name" >
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-12 cc_info_box">
                                                        <p>Product URL</p>
                                                        <input class="form-control cc_input_fields" id="productName" [(ngModel)]="crate.product_url" >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 col-sm-12 cc_info_box">
                                                        <p>Coupon Code for Canvas Crate Units:</p>
                                                        <input class="form-control cc_input_fields" id="crate_promo_code" [(ngModel)]="crate.crate_promo_code" >
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-12 cc_info_box">
                                                        <p>Exclusive Coupon Code for Consumer Retargeting:</p>
                                                        <input class="form-control cc_input_fields" id="exclusive_code" [(ngModel)]="crate.exclusive_code" >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                        
                                        <!-- Crate media -->
                                        <div class="cc_media_column">
                        
                                            <div class="cc_video_section">
                        
                                                <div class="media_label">
                                                    <div class="label">Matched Video</div>
                                                    <div class="suggestion">Suggested Length: 60s or less</div>
                                                </div>
                        
                                                <ng-container *ngIf="crate.video_media[0]; else videoPreview1">
                                                    <div (click)="fileInput1.click()" class="delete-icon-large-tile" style="top: 46px">
                                                        <img alt="edit" loading="lazy" src="assets/images/icons/cc_bin.png">
                                                        <input #fileInput1 (click)="deleteFile(crate.id, crate.video_media[0].id)" style="display:none;"/>
                                                    </div>
                                                    <!-- <video src="https://cdn.tailorie.com/media/uploads/users/6344/posts/Recommendation/1681183734.2348454-original.mp4" controls preload="metadata" class="video_preview"></video> -->
                                                    <video [src]="crate.video_media[0].media" controls preload="metadata" class="video_preview"></video>
                                                </ng-container>
                        
                                                <ng-template #videoPreview1>
                                                    <div appDrag (files)="directUpload(crate.id, $event)" (click)="fileInput1.click()" style="cursor: pointer;">
                                                        <input #fileInput1 (change)="directUpload(crate.id, $event)"
                                                            accept="video/*" style="display:none;" type="file"/>
                                                        <div class="upload_box_large video_upload_box">
                                                            <img alt="cloud" style="width: 36px;" loading="lazy" src="assets/images/icons/orange_cloud.png">
                                                            <div class="upload_text_large">Drag & Drop or <br><span class="orange">Choose File</span> to upload</div>
                                                            <div class="file_info">Aspect Ratio 9:16 MP4 or MOV<br />Max file size<br />250 MB</div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </div>
                        
                                            <div class="cc_notification_section">
                        
                                                <div class="media_label">
                                                    <div class="label">Match Notification Image</div>
                                                </div>
                        
                                                <ng-container *ngIf="crate.notification_media[0]; else imagePreview0">
                                                    <div (click)="fileInput2.click()" class="delete-icon-large-tile notification_sec">
                                                        <img alt="edit" loading="lazy" src="assets/images/icons/cc_bin.png">
                                                        <input #fileInput2 (click)="deleteFile(crate.id, crate.notification_media[0].id)" style="display:none;"/>
                                                    </div>
                                                    <!-- <img src="https://cdn.tailorie.com/media/canvas_crate_media/Untitled_design-comp-1_9Xftdin.png" class="notification_preview"> -->
                                                    <img [src]="crate.notification_media[0].media" alt="" class="notification_preview">
                                                </ng-container>
                                                <ng-template #imagePreview0>
                                                    <div appDrag (files)="saveFiles(crate.id, [$event[0].file], 2)" (click)="fileInput2.click()" style="cursor: pointer;">
                                                        <input #fileInput2 (change)="saveFiles(crate.id, $event.target.files, 2)"
                                                            accept=".png, .jpg" style="display:none;" type="file"/>
                                                        <div class="upload_box_large notification_upload_box">
                                                            <img alt="cloud" style="width: 36px;" loading="lazy" src="assets/images/icons/orange_cloud.png">
                                                            <div class="upload_text_large">Drag & Drop or <br><span class="orange">Choose File</span> to upload</div>
                                                            <div class="file_info">Aspect Ratio 1:1 JPG or PNG<br />Max file size 2 MB</div>
                                                        </div>
                                                    </div>
                                                    </ng-template>
                                            </div>
                        
                                            <!-- 4 small images -->
                        
                                            <div class="other_images_section">
                        
                                                <div class="media_label">
                                                    <div class="label">Product Images</div>
                                                    <div class="suggestion">Suggested: 3-4 Images</div>
                                                </div>
                        
                                                <div class="other_images_board">
                        
                                                    <!-- 1 image -->
                                                    <div class="image_couple">
                                                        <div class="small_image_section" appDrag (files)="saveFiles(crate.id, [$event[0].file], 3)">
                                                            <ng-container *ngIf="crate.image_media[0]; else imagePreview1">
                                                                <div (click)="fileInput3.click()" class="delete-icon-small-tile">
                                                                    <img alt="edit" loading="lazy" src="assets/images/icons/cc_bin.png">
                                                                    <input #fileInput3 (click)="deleteFile(crate.id, crate.image_media[0].id)" style="display:none;"/>
                                                                </div>
                                                                <!-- <img src="https://cdn.tailorie.com/media/canvas_crate_media/9x16__1-comp-2.png" class="small_image_preview"> -->
                                                                <img [src]="crate.image_media[0].media" alt="" class="small_image_preview">
                                                            </ng-container>
                                                            <ng-template #imagePreview1>
                                                                <div (click)="fileInput3.click()" style="cursor: pointer;" class="small_upload_section">
                                                                    <input #fileInput3 (change)="saveFiles(crate.id, $event.target.files, 3)"
                                                                        accept=".png, .jpg" style="display:none;" type="file"/>
                                                                    <img alt="cloud" style="width: 24px;" loading="lazy" src="assets/images/icons/orange_cloud.png">
                                                                    <div class="upload_text_small">Drag & Drop or <br><span class="orange">Choose File</span> to upload</div>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                        <div class="small_image_section" appDrag (files)="saveFiles(crate.id, [$event[0].file], 4)">
                                                            <ng-container *ngIf="crate.image_media[1]; else imagePreview2">
                                                                <div (click)="fileInput4.click()" class="delete-icon-small-tile">
                                                                    <img alt="edit" loading="lazy" src="assets/images/icons/cc_bin.png">
                                                                    <input #fileInput4 (click)="deleteFile(crate.id, crate.image_media[1].id)" style="display:none;"/>
                                                                </div>
                                                                <!-- <img src="https://cdn.tailorie.com/media/canvas_crate_media/9x16__1-comp-2.png" class="small_image_preview"> -->
                                                                <img [src]="crate.image_media[1].media" alt="" class="small_image_preview">
                                                            </ng-container>
                                                            <ng-template #imagePreview2>
                                                                <div (click)="fileInput4.click()" style="cursor: pointer;" class="small_upload_section">
                                                                    <input #fileInput4 (change)="saveFiles(crate.id, $event.target.files, 4)"
                                                                        accept=".png, .jpg" style="display:none;" type="file"/>
                                                                    <img alt="cloud" style="width: 24px;" loading="lazy" src="assets/images/icons/orange_cloud.png">
                                                                    <div class="upload_text_small">Drag & Drop or <br><span class="orange">Choose File</span> to upload</div>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="image_couple">
                                                        <div class="small_image_section" appDrag (files)="saveFiles(crate.id, [$event[0].file], 5)">
                                                            <ng-container *ngIf="crate.image_media[2]; else imagePreview3">
                                                                <div (click)="fileInput5.click()" class="delete-icon-small-tile">
                                                                    <img alt="edit" loading="lazy" src="assets/images/icons/cc_bin.png">
                                                                    <input #fileInput5 (click)="deleteFile(crate.id, crate.image_media[2].id)" style="display:none;"/>
                                                                </div>
                                                                <img [src]="crate.image_media[2].media" alt="" class="small_image_preview">
                                                            </ng-container>
                                                            <ng-template #imagePreview3>
                                                                <div (click)="fileInput5.click()" style="cursor: pointer;" class="small_upload_section">
                                                                    <input #fileInput5 (change)="saveFiles(crate.id, $event.target.files, 5)"
                                                                        accept=".png, .jpg" style="display:none;" type="file"/>
                                                                    <img alt="cloud" style="width: 24px;" loading="lazy" src="assets/images/icons/orange_cloud.png">
                                                                    <div class="upload_text_small">Drag & Drop or <br><span class="orange">Choose File</span> to upload</div>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                        <div class="small_image_section" appDrag (files)="saveFiles(crate.id, [$event[0].file], 6)">
                                                            <ng-container *ngIf="crate.image_media[3]; else imagePreview4">
                                                                <div (click)="fileInput6.click()" class="delete-icon-small-tile">
                                                                    <img alt="edit" loading="lazy" src="assets/images/icons/cc_bin.png">
                                                                    <input #fileInput6 (click)="deleteFile(crate.id, crate.image_media[3].id)" style="display:none;"/>
                                                                </div>
                                                                <img [src]="crate.image_media[3].media" alt="" class="small_image_preview">
                                                            </ng-container>
                                                            <ng-template #imagePreview4>
                                                                <div (click)="fileInput6.click()" style="cursor: pointer;" class="small_upload_section">
                                                                    <input #fileInput6 (change)="saveFiles(crate.id, $event.target.files, 6)"
                                                                        accept=".png, .jpg" style="display:none;" type="file"/>
                                                                    <img alt="cloud" style="width: 24px;" loading="lazy" src="assets/images/icons/orange_cloud.png">
                                                                    <div class="upload_text_small">Drag & Drop or <br><span class="orange">Choose File</span> to upload</div>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                        
                                        <!-- Crate description -->
                                        <div class="product_description">
                                            <div class="label">Product Description</div>
                                            <textarea [(ngModel)]="crate.product_description"
                                                    [ngClass]=""
                                                    class="form-control description_box"
                                                    ></textarea>
                                            <label class="description-character-count" style="padding-top: 10px;">
                                                {{crate.product_description.length || 0}}/500 Characters
                                            </label>
                                        </div>
                        
                                        <ng-container *ngIf="crate.is_active">
                                            <div class="form-group float-right">
                                                <button (click)="updateCanvasCrateForm(crate)" class="btn7 border-0 save_button">
                                                    <span *ngIf="!isLoading" class="save_text">Save changes</span>
                                                    <span *ngIf="isLoading" class="tiny-loader"></span>
                                                </button>
                                            </div>
                                        </ng-container>
                        
                                    </div>
                                        
                                </ng-container>

                            </div>
                        </div>

                        <!---------------------- Past / Expired Crates  ---------------------->
                        <div #pop3 aria-labelledby="pop3-tab" class="tab-pane fade bs-tab-content" id="pop3" role="tabpanel">
                            <div class="pt-3"></div>

                            <div class="display-crates">
                                <ng-container *ngFor="let crate of expired_crates; let i = index">
                                    <ng-container *ngIf="!crate.is_active">

                                        <ng-container *ngIf="i !== 0">
                                            <div class="expired_crate_divider"></div>
                                        </ng-container>

                                        <div class="mt-3">
                                            
                                            <!---------------------- Web View  ---------------------->
                                            <div class="past_crate_box">
                                                <ng-container *ngIf="crate.notification_media?.length > 0; else onPlaceImage" class="pcc_left">
                                                    <img [src]="crate.notification_media[0].media" alt="" class="past_crate_image">
                                                </ng-container>
                                                <ng-template #onPlaceImage>
                                                    <img [src]="" alt="" class="past_crate_image">
                                                </ng-template>

                                                <div class="pcc_middle">
                                                    <div class="pc_title">{{crate.product_name}}</div>

                                                    <div class="pc_dates">

                                                        <div class="pc_start">
                                                            Crate Start Date
                                                            <div class="s_date">
                                                                {{ (crate.billing_start  | date:'MM/dd/yyyy') || 'Not Live' }}
                                                            </div>
                                                        </div>

                                                        <div class="pc_expiry">
                                                            Crate Expiry Date
                                                            <div class="e_date">
                                                                {{ (crate.billing_end  | date:'MM/dd/yyyy') || 'Not Live' }}
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <button (click)="duplicateCanvasCrate(crate.id)" class="btn7 border-0 duplicate_crate">
                                                        <span class="dc_text">Duplicate Crate</span>
                                                    </button>
                                                </div>

                                                <div class="pcc_right">
                                                    <div class="exp_crate_report_buttons">
                                                        <a class="report_links" target="_blank" *ngIf="crate.crate_report">
                                                            <div class="exp_crate_report_button orange_button"  (click)="openPopup(crate)">
                                                                <img loading="lazy" src="assets/images/icons/orange_pdf.png">
                                                                <div class="report_text">Crate Report</div>
                                                            </div>
                                                        </a>
                                                        <div class="popup" *ngIf="isPopupOpen && crate.crate_report && openedcrate==crate">
                                                            <button class="close_button" (click)="closePopup()">X</button>
                                                            <div class="popup_content">
                                                                <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(crate.crate_report)"></iframe>
                                                            </div>
                                                        </div>
                                                        <a *ngIf="!crate.crate_report">
                                                            <div class="exp_crate_report_button" *ngIf="!crate.crate_report">
                                                                <img loading="lazy" src="assets/images/icons/grey_pdf.png">
                                                                <div class="report_text">Crate Report</div>
                                                            </div>
                                                        </a>
                            
                                                        <a class="report_links" target="_blank" *ngIf="crate.crate_recipients" href="{{crate.crate_recipients}}">
                                                            <div class="exp_crate_report_button orange_button">
                                                                <img loading="lazy" src="assets/images/icons/orange_csv.png">
                                                                <div class="report_text">Crate Recipients</div>
                                                            </div>
                                                        </a>
                                                        <a *ngIf="!crate.crate_recipients">
                                                            <div class="exp_crate_report_button"  *ngIf="!crate.crate_recipients">
                                                                <img loading="lazy" src="assets/images/icons/grey_csv.png">
                                                                <div class="report_text">Crate Recipients</div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                            <!---------------------- Mobile View  ---------------------->
                                            <div class="mobile_past_crate_box">
                                                <div class="pc_title">{{crate.product_name}}</div>

                                                <div class="crate_report_buttons">
                        
                                                    <a class="report_links" target="_blank" *ngIf="crate.crate_report">
                                                        <div class="crate_report_button orange_button"  (click)="openPopup(crate)">
                                                            <img loading="lazy" src="assets/images/icons/orange_pdf.png">
                                                            <div class="report_text">Crate Report</div>
                                                        </div>
                                                    </a>
                                                    <div class="popup" *ngIf="isPopupOpen && crate.crate_report && openedcrate==crate">
                                                        <button class="close_button" (click)="closePopup()">X</button>
                                                        <div class="popup_content">
                                                            <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(crate.crate_report)"></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="crate_report_button" *ngIf="!crate.crate_report">
                                                        <img loading="lazy" src="assets/images/icons/grey_pdf.png">
                                                        <div class="report_text">Crate Report</div>
                                                    </div>
                        
                                                    <a class="report_links" target="_blank" *ngIf="crate.crate_recipients" href="{{crate.crate_recipients}}">
                                                        <div class="crate_report_button orange_button">
                                                            <img loading="lazy" src="assets/images/icons/orange_csv.png">
                                                            <div class="report_text">Crate Recipients</div>
                                                        </div>
                                                    </a>
                                                    <div class="crate_report_button"  *ngIf="!crate.crate_recipients">
                                                        <img loading="lazy" src="assets/images/icons/grey_csv.png">
                                                        <div class="report_text">Crate Recipients</div>
                                                    </div>
                                                </div>

                                                <div class="pc_dates">

                                                    <div class="pc_start">
                                                        Crate Start Date
                                                        <div class="s_date">
                                                            {{ (crate.billing_start  | date:'MM/dd/yyyy') || 'Not Live' }}
                                                        </div>
                                                    </div>
    
                                                    <div class="pc_expiry">
                                                        Crate Expiry Date
                                                        <div class="e_date">
                                                            {{ (crate.billing_end  | date:'MM/dd/yyyy') || 'Not Live' }}
                                                        </div>
                                                    </div>
    
                                                </div>

                                                <ng-container *ngIf="crate.notification_media?.length > 0; else onPlaceImage">
                                                    <img [src]="crate.notification_media[0].media" alt="" class="past_crate_image">
                                                </ng-container>

                                                <ng-template #onPlaceImage>
                                                    <img [src]="" alt="" class="past_crate_image">
                                                </ng-template>

                                                <button (click)="duplicateCanvasCrate(crate.id)" class="btn7 border-0 duplicate_crate">
                                                    <span class="dc_text">Duplicate Crate</span>
                                                </button>

                                            </div>
                            
                                        </div>

                                    </ng-container>
                                </ng-container>

                            </div>

                        </div>
                    </div>

                </section>
            </div>
        </div>
    </section>

    <!------------------------------------ Crates ------------------------------------>
</div>
