import { environment } from '../../../../environments/environment';
import { ApiService } from '../api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AuthService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
        this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }
    get currentUserValue() {
        return this.currentUserSubject.value;
    }
    resetCurrentUserValue() {
        this.currentUserSubject.next(null);
    }
    localUserObject() {
        return JSON.parse(localStorage.getItem('currentUser'));
    }
    updateUser(user) {
        localStorage.setItem('authToken', user.token);
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
    }
    updateCurrentUserBrand(updatedBrand) {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.user) {
            currentUser.user.branduserprofile.brand = updatedBrand;
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
            this.updateUser(currentUser);
        }
        else {
            window.location.reload();
        }
    }
    GetAuthToken(email, password) {
        return this.http
            .post(`${environment.apiBaseUrl}/api-token-auth/`, {
            email,
            password,
        }, this.httpOptions)
            .pipe(map((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('authToken', user.token);
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
            return user;
        }));
    }
    isAuthenticated() {
        return new Promise((resolve, reject) => {
            const user = this.currentUserValue;
            if (!user) {
                reject(user);
            }
            else {
                this.refreshAuthToken().subscribe(
                // tslint:disable-next-line:no-shadowed-variable
                (user) => {
                    resolve(this.currentUserValue);
                }, err => {
                    console.log(err);
                    this.logout();
                });
            }
        });
    }
    refreshAuthToken() {
        const currentUser = this.currentUserValue;
        return this.http
            .post(`${environment.apiBaseUrl}/api-token-refresh/`, {
            token: currentUser.token,
        }, this.httpOptions)
            .pipe(map((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (user.user.user_type !== 'brand') {
                this.logout();
            }
            else {
                this.updateUser(user);
                return user;
            }
        }));
    }
    validateAuthToken() {
        const currentUser = this.currentUserValue;
        return this.http
            .post(`${environment.apiBaseUrl}/api-token-verify/`, {
            token: currentUser.token,
        }, this.httpOptions)
            .pipe(map((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (user.user.user_type !== 'brand') {
                this.logout();
                return user;
            }
            else {
                this.refreshAuthToken().subscribe((updatedUser) => {
                    localStorage.setItem('authToken', updatedUser.token);
                    localStorage.setItem('currentUser', JSON.stringify(updatedUser));
                    this.currentUserSubject.next(updatedUser);
                    return updatedUser;
                }, (err) => {
                    console.log(err);
                    this.logout();
                    return user;
                });
            }
        }));
    }
    logout() {
        // remove user from local storage to log user out
        // localStorage.removeItem('authToken');
        // localStorage.removeItem('currentUser');
        localStorage.clear();
        this.currentUserSubject.next(null);
        window.location.href = '/login';
    }
    RequestPasswordReset(email) {
        return this.http
            .post(`${environment.apiBaseUrl}/api/password_reset/`, {
            email,
        }, this.httpOptions)
            .pipe();
    }
    ResetPasswordConfirm(token, password) {
        return this.http
            .post(`${environment.apiBaseUrl}/api/password_reset/confirm/`, {
            token,
            password,
        }, this.httpOptions)
            .pipe();
    }
    ResetPasswordValidateToken(token) {
        return this.http
            .post(`${environment.apiBaseUrl}/api/password_reset/validate_token/`, {
            token,
        }, this.httpOptions)
            .pipe();
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthService, providedIn: "root" });
