import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CanvasCrateService} from '../shared/services/canvas_crate/canvas-crate.service';
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {AuthenticatedUser} from '../shared/services/auth/authenticateduser';
import {Brand} from "../shared/services/brand/brand";
import {ToastrService} from "ngx-toastr";
import {DomSanitizer} from "@angular/platform-browser";
import * as tus from 'tus-js-client';
import {VideoUploadServiceService} from '../shared/services/video-upload-service.service'
import { AuthService } from '../shared/services/auth/auth.service';

@Component({
    selector: 'app-canvas-crate',
    templateUrl: './canvas-crate.component.html',
    styleUrls: ['./canvas-crate.component.scss']
})
export class CanvasCrateComponent implements OnInit {
    currentUser: AuthenticatedUser;
    currentBrandInfo: Brand;

    form: FormGroup;
    public future_crates = [];
    public active_crates = [];
    public expired_crates = [];

    newCrateData = {
        product_name:null,
        product_url:null,
        product_description:'',
        crate_promo_code:null,
        exclusive_code:null
    };
    selectedTab = null;
    files: any = [];
    flashMessage: 'success' | 'error' | null;
    isLoading = false;
    alertMessage = '';
    submitted = false;
    cdnUrl = environment.webStaticResources;
    private showAlertErrorMassage: boolean;
    private alertTimeout: NodeJS.Timer;
    private alertErrorTimeout: NodeJS.Timer;
    private showAlertMessage: boolean;
    private crateVideo;

    isPopupOpen: boolean = false;
    openedcrate = null;

    openPopup(crate) {
        this.isPopupOpen = true;
        this.openedcrate = crate;
    }

    closePopup() {
        this.isPopupOpen = false;
        this.openedcrate = null;
    }
    constructor(
        public fb: FormBuilder,
        // tslint:disable-next-line:no-shadowed-variable
        private CanvasCrateService: CanvasCrateService,
        private route: ActivatedRoute,
        public toastr: ToastrService,
        private changeDetectorRef: ChangeDetectorRef,
        public router: Router,
        public sanitizer: DomSanitizer,
        private videoUploadingService: VideoUploadServiceService,
        private authService: AuthService,

    ) {
        this.authService.currentUser.subscribe(x => this.currentUser = x);
        this.form = this.fb.group({
            product_description: ['']
        });
    }


    ngOnInit() {
        this.listCanvasCratesData();

        if (this.route.snapshot.fragment) {
            this.selectedTab = this.route.snapshot.fragment;
        } else {
            this.selectedTab = 'view-cc';
        }

        this.CanvasCrateService.listcrate.subscribe(value => {
            if (value) {
                this.listCanvasCratesData();            
            }
        });
    }

    toggleCrate(crateId): any {
        if ( document.getElementById(crateId).classList.contains('show') ) {
            document.getElementById(crateId).classList.remove('show');
        } else {
            document.getElementById(crateId).classList.add('show');
        }
    }

    // List all Canvas Crate of Brand
    listCanvasCratesData(): any {
        this.CanvasCrateService.ListCanvasCrates().subscribe((results) => {
            this.future_crates = results.future_crates;
            this.active_crates = results.active_crates;
            this.expired_crates = results.expired_crates;
            this.changeDetectorRef.markForCheck();

            if (results.last_crate){
                this.newCrateData.product_name = results.last_crate.product_name;
                this.newCrateData.product_description = results.last_crate.product_description;
                this.newCrateData.product_url = results.last_crate.product_url;
            }
        });
    }

    CreateCanvasCrateForm(crate) {
        this.submitted = true;
        this.isLoading = true;
        console.log(crate)

        const fd = new FormData();
        fd.append('product_description', crate.product_description || '')
        fd.append('product_name', crate.product_name || '')
        fd.append('product_url', crate.product_url || '')
        fd.append('crate_promo_code', crate.crate_promo_code || '')
        fd.append('exclusive_code', crate.exclusive_code || '')

        this.CanvasCrateService.CreateCanvasCrate(fd).subscribe((res: any) => {
            this.listCanvasCratesData();

            setTimeout(() => {
                this.toastr.success('Changes Saved Successfully!');
                this.isLoading = false;
                this.submitted = false;
            }, 500);

        }, (err: any) => {
            this.toastr.error('An error occurred, try again!');
            this.isLoading = false;
            this.submitted = false;
        });
    }

    // Create Duplicate Crate Logic
    duplicateCanvasCrate(crate_id) {
        this.CanvasCrateService.DuplicateCanvasCrate(crate_id).subscribe((res: any) => {
            this.listCanvasCratesData();
            if (res) {
                this.changeDetectorRef.markForCheck();

                setTimeout(() => {
                    this.toastr.success('Created Duplicated Crate!');

                    // Redirect to future crate tab
                    const tab_1_div = document.getElementById('pop1') as HTMLDivElement;
                    const tab_1_link = document.getElementById('poplink1') as HTMLAnchorElement;
                    this.showTabContent(tab_1_div, tab_1_link)
                }, 500);
            }
        }, (err: any) => {
            this.toastr.error('An error occurred, try again!');
        });
    }


    updateCanvasCrateForm(crate) {
        // console.log('cart', crate);
        this.submitted = true;
        this.isLoading = true;

        const fd = new FormData();
        fd.append('product_description', crate.product_description)
        fd.append('product_name', crate.product_name)
        fd.append('product_url', crate.product_url)
        fd.append('crate_promo_code', crate.crate_promo_code)
        fd.append('exclusive_code', crate.exclusive_code)

        this.CanvasCrateService.UpdateCanvasCrate(crate.id, fd).subscribe((res: any) => {
            this.listCanvasCratesData();
            // console.log(this.form.value);
            if (res) {
                this.changeDetectorRef.markForCheck();

                setTimeout(() => {
                    this.toastr.success('Changes Saved Successfully!');
                    this.isLoading = false;
                    this.submitted = false;
                }, 500);
            }
        }, (err: any) => {
            this.toastr.error('An error occurred, try again!');
            this.isLoading = false;
            this.submitted = false;
        });
    }


    // save files in db and store response ids in array
    saveFiles(crateid: number, attachment: any, media_type: any): any {
        
        console.log(attachment[0].type)
        console.log(['video/mp4', 'video/quicktime'].includes(attachment[0].type))

        if (media_type === 1 && (!['video/mp4', 'video/quicktime'].includes(attachment[0].type))){
            this.toastr.error('This file type is not supported!, supported types .mp4 or .mov')
        } else if (media_type != 1 && (!['image/jpg', 'image/png', 'image/jpeg'].includes(attachment[0].type))) {
            this.toastr.error('This file type is not supported!, supported types .png, .jpg or .jpeg')
        } else {
            const fd = new FormData();
            // console.log(attachment[0])
            const promise = new Promise((resolve, reject) => {
                fd.append('file', attachment[0]);
                // fd.append('media_id', mediaId);
                fd.append('media_type', media_type)  // 1-represents video 2-Notification image rest as images
                // console.log('mediaId', mediaId);
                this.CanvasCrateService.UploadCanvasCrateMedia(crateid, fd).subscribe(resp => {
                        if (resp) {
                            this.future_crates = resp.future_crates;
                            this.active_crates = resp.active_crates;
                            this.expired_crates = resp.expired_crates;
                            this.changeDetectorRef.markForCheck();
                            this.toastr.success('Media Uploaded Successfully!');
                        }
                    },
                    (error) => {
                        if (error) {
                            this.toastr.error('An error occurred, try again!')
                        }
                    });
            });
            promise.then((data) => {
                if (data) {
                    this.listCanvasCratesData();
                }
            });
            
        }
    }

    deleteFile(crateid: number, mediaId: any): any {

        const promise = new Promise((resolve, reject) => {
            this.CanvasCrateService.DeleteCanvasCrateMedia(crateid, mediaId).subscribe(resp => {
                    if (resp) {
                        
                        // this.active_crates = this.active_crates.map(c_crate => {
                        //     console.log(c_crate)
                        //     if (c_crate.notification_media) {
                        //         c_crate.notification_media = c_crate.notification_media.filter(nestedObj => nestedObj.id !== mediaId);
                        //     }
                        //     if (c_crate.image_media) {
                        //         c_crate.image_media = c_crate.image_media.filter(nestedObj => nestedObj.id !== mediaId);
                        //     }
                        //     if (c_crate.video_media) {
                        //         c_crate.video_media = c_crate.video_media.filter(nestedObj => nestedObj.id !== mediaId);
                        //     }
                        //   });

                        //   this.future_crates = this.future_crates.map(c_crate => {
                        //     if (c_crate.notification_media) {
                        //         c_crate.notification_media = c_crate.notification_media.filter(nestedObj => nestedObj.id !== mediaId);
                        //     }
                        //     if (c_crate.image_media) {
                        //         c_crate.image_media = c_crate.image_media.filter(nestedObj => nestedObj.id !== mediaId);
                        //     }
                        //     if (c_crate.video_media) {
                        //         c_crate.video_media = c_crate.video_media.filter(nestedObj => nestedObj.id !== mediaId);
                        //     }
                        //   });
                          
                        //   this.expired_crates = this.expired_crates.map(expired_crates => {
                        //     if (expired_crates.notification_media) {
                        //         expired_crates.notification_media = expired_crates.notification_media.filter(nestedObj => nestedObj.id !== mediaId);
                        //     }
                        //   });

                        this.future_crates = resp.future_crates;
                        this.active_crates = resp.active_crates;
                        this.expired_crates = resp.expired_crates;

                        this.changeDetectorRef.markForCheck();
                        this.toastr.success('Media Deleted Successfully!');
                    }
                },
                (error) => {
                    if (error) {
                        this.toastr.error('An error occurred, try again!')
                    }
                });
        });
        promise.then((data) => {
            if (data) {
                this.listCanvasCratesData();
            }
        });
    }

    // loadMenu(selectedItem) {
    //     this.showTabContent(selectedItem.id, selectedItem.fragment, '#view-cc');
    // }

    showAlert(message: string) {
        this.showAlertErrorMassage = false;
        clearTimeout(this.alertTimeout);
        clearTimeout(this.alertErrorTimeout);
        this.showAlertMessage = true;
        this.alertMessage = message;
        this.alertTimeout = setTimeout(() => {
            this.showAlertMessage = false;
            this.alertMessage = '';
        }, 5000);
    }

    private showFlashMessage(type: 'success' | 'error'): void {
        // Show the message
        this.flashMessage = type;

        // Hide it after 3 seconds
        setTimeout(() => {
            this.flashMessage = null;
            this.submitted = false;
        }, 4000);
    }

    directUpload(crateid, $event: Event) {
        this.crateVideo = null;
        // @ts-ignore
        this.crateVideo = $event.target.files[0];
        setTimeout(() => {
            this.uploadMoreVideosTUSWay(crateid);
        }, 500);
    }

    uploadMoreVideosTUSWay(crateid) {
        if (this.crateVideo) {
            // const mbSize = videoFile.size / 1024 / 1024;
            const fileIsMp4 = (this.crateVideo.type === 'video/mp4' || 'video/3gpp' || 'video/m4v'
                || 'application/x-mpegURL' || 'video/MP2T' || 'video/quicktime'
                // || 'video/x-msvideo' || 'video/x-flv'
                || 'video/x-m4v' || 'video/wmv');

            if (!fileIsMp4) {
                this.toastr.error('Videos Files supported Only');
                return false;
            } else {

                // -----------------------------   TUS UPLOADER

                this.videoUploadingService.showUploadBar();

                // Get the selected file from the input element
                const file = this.crateVideo;
                let currPer = 0;

                const serv = this.CanvasCrateService
                const toastrSer = this.toastr;
                const list_canvas = this.listCanvasCratesData()

                const envUrl = environment.apiBaseUrl || 'http://192.168.100.113:8000';
                const uploadAPIUrl = envUrl + '/api/v1/files/';
                localStorage.setItem('pageReload', 'false');
                localStorage.setItem('videoUploaded', 'false');
                const videoUploaded = localStorage.getItem('videoUploaded');

                // Create a new tus upload
                const upload = new tus.Upload(file, {
                    endpoint: uploadAPIUrl,
                    retryDelays: [0, 3000, 5000, 10000, 20000],
                    metadata: {
                        filename: file.name,
                        filetype: file.type
                    },
                    headers: {
                        Authorization: 'Bearer ' + this.currentUser.token
                    },
                    onError(error) {
                        console.log('Failed because: ' + error);
                    },
                    onProgress(bytesUploaded, bytesTotal) {

                        document.getElementById('video-upload-wrapper').classList.add('showBar');

                        const percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
                        console.log(bytesUploaded, bytesTotal, percentage + '%');
                        currPer = Math.round(+percentage);
                        if (+percentage > 70 && +percentage < 90) {
                            document.getElementById('progress-bar').style.width = +currPer + '%';
                        } else if (+percentage > 95 && +percentage <= 100) {
                            document.getElementById('progress-bar').style.width = '95%';
                        } else {
                            document.getElementById('progress-bar').style.width = +percentage + '%';
                        }

                    },
                    onSuccess() {
                        if ('name' in upload.file) {
                            console.log('Download %s from %s', upload.file.name, upload.url);
                        } else {
                            console.log('Download %s from %s', upload.file, upload.url);
                        }
                        if (videoUploaded === 'false') {
                            if (currPer === 100) {
                                const upUrl = upload.url.split('/');
                                const guid = upUrl[upUrl.length - 2];

                                const fd = new FormData();
                                fd.append('file', null);
                                fd.append('guid', guid);
                                fd.append('media_type', '1');

                                serv.UploadCanvasCrateMedia(crateid, fd).subscribe(resp => {
                                    localStorage.setItem('videoUploaded', 'true');
                                    document.getElementById('uploading-text').innerText = 'Uploading Video';
                                        setTimeout(() => {
                                        document.getElementById('uploading-text').innerText = 'Success! Your video has been uploaded.';
                                        document.getElementById('progress-bar').style.width = '100%';
                                        localStorage.setItem('pageLoad', 'slow');

                                        setTimeout(() => {
                                            document.getElementById('video-upload-wrapper').classList.remove('showBar');
                                            const toReload = localStorage.getItem('pageReload');


                                            if (toReload === 'true') {
                                                window.localStorage.removeItem('videoUploaded');
                                                window.localStorage.removeItem('pageReload');
                                            }
                                        }, 2000);

                                        // Observable to Render DOM to detect changes in crates
                                        serv.setlistCrates(true);

                                    }, 5000);
                                    
                                }, error => {
                                    toastrSer.error('An error occurred, try again!');
                                });
                                }
                        } else {
                            if (currPer === 100) {
                                const upUrl = upload.url.split('/');
                                const guid = upUrl[upUrl.length - 2];
                                const fd = new FormData();
                                fd.append('file', null);
                                fd.append('guid', guid);
                                fd.append('media_type', '1');

                                serv.UploadCanvasCrateMedia(crateid, fd).subscribe(resp => {
                                    localStorage.setItem('videoUploaded', 'true');
                                    document.getElementById('uploading-text').innerText = 'Uploading Video';
                                    setTimeout(() => {
                                        document.getElementById('uploading-text').innerText = 'Success! Your video has been uploaded.';
                                        localStorage.setItem('pageLoad', 'slow');

                                        setTimeout(() => {
                                            document.getElementById('video-upload-wrapper').classList.remove('showBar');
                                            const toReload = localStorage.getItem('pageReload');

                                            if (toReload === 'true') {
                                                window.localStorage.removeItem('videoUploaded');
                                                window.localStorage.removeItem('pageReload');
                                            }
                                        }, 2000);

                                        // Observable to Render DOM to detect changes in crates
                                        serv.setlistCrates(true);

                                    }, 5000);

                                }, error => {
                                    toastrSer.error('An error occurred while uploading video, try again!');
                                });
                            }
                        }
                    },

                });

                // Start the upload
                upload.start();

                // -----------------------------   .end TUS UPLOADER

            }
        }
    }

    showTabContent(pop: HTMLDivElement, link: HTMLAnchorElement) {
        const links = document.getElementsByClassName('bs-tab-link');
        const tabPanes = document.getElementsByClassName('bs-tab-content');

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < tabPanes.length; i++) {
            tabPanes[i].classList.remove('show', 'active');
        }
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < links.length; j++) {
            links[j].classList.remove('active');
        }
        pop.classList.add('show', 'active');
        link.classList.add('active');
    }
}
