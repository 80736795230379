<main class="createAccount">

    <header class="content-header">
        <nav class="navbar navbar-expand-md navbar-light">
            <div class="container-fluid">
                <a class="navbar-brand head" href="https://tailorie.com">
                    <img alt="" src="{{cdnUrl}}/assets/images/Tailorie-logo_dark.svg">
                    <span class="title-brand">Brand Account</span>
                </a>
                <button (click)="openDropdown()" aria-controls="brandAccountNav" aria-expanded="false"
                        aria-label="Toggle navigation" class="navbar-toggler">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="brandAccountNav">
                    <ul class="navbar-nav ml-auto text-center">
                        <li class="nav-item mr-lg-4">
                            <a (click)="logout()" class="nav-link px-2" href="#">Logout</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://tailorie.com/contact-us">Get Help</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>

    <!-- Content Header (Page header) -->
    <!--    <section class="content-header">-->
    <!--        <div class="container-fluid">-->
    <!--            <div class="row justify-content-between align-items-center pt-2">-->
    <!--                <div class="col-12 d-flex justify-content-between">-->
    <!--                    <a [routerLink]="['/']" class="head" href="#">-->
    <!--                        <img alt="" src="{{cdnUrl}}/assets/images/Tailorie-logo_dark.svg">-->
    <!--                        <span class="title-brand">Brand Account</span>-->
    <!--                    </a>-->
    <!--                    <h5>-->
    <!--                        <a (click)="logout()" class="nav-link mt-2"-->
    <!--                           style="margin-right: 20px; cursor: pointer">Logout</a>-->
    <!--                        <a class="nav-link mt-2" href="/contact-us">Get Help</a>-->
    <!--                    </h5>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </section>-->
    <div class="progress" style="height: 5px;">
        <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" class="progress-bar" id="myBar" role="progressbar"
             style="width: 10%; background-color:#034B4E"></div>
    </div>

    <!-- Main content -->
    <div *ngIf="error" class="alert alert-danger">{{error}}</div>

    <!---------------------------------------- TAB 1 (USER SIGN UP)---------------------------------------------->

    <p *ngIf="uploadlink" class="uploadlink">
        We’re happy to help you add your video to Tailorie if needed!
        <a href="https://docs.google.com/forms/d/1kFLzQEhlRRPGm4O4KSJuRG-c3d4g4FqkZKGbA1Gxu58" style="color: #ffffff"
           target="_blank">Let us know!</a>
    </p>

    <div *ngIf="showTab == 1" class="create-account-tab tab-1">
        <img class="tab-image" src="{{cdnUrl}}/assets/images/brand-account-images/image5.png">
        <div class="container">
            <div class="row main-brand-form justify-content-center align-items-center">
                <div class="col-md-6 col-lg-5">
                    <h4>Create Your Account</h4>
                    <p class="sub-head">Basic Info</p>
                    <form [formGroup]="brandUserRegistrationForm" class="overflow-hidden pb-1">
                        <div class="form-group">
                            <p class="ca-label">Business Name</p>
                            <input (blur)="setUsername()"
                                   [ngClass]="{ 'is-invalid': (burf.business_name.dirty || brandUserRegistrationFormSubmitted) && burf.business_name.errors }"
                                   class="form-control" formControlName="business_name" required type="text">

                            <div *ngIf="(burf.business_name.dirty || brandUserRegistrationFormSubmitted) && (burf.business_name.errors) "
                                 class="invalid-feedback">
                                <div *ngIf="burf.business_name.errors.required">What’s the name of your business?</div>
                                <div *ngIf="burf.business_name.errors.minlength">Business Name should not be less than 3
                                    characters
                                </div>
                                <div *ngIf="burf.business_name.errors.notUniqueBrandName">Business Name is not
                                    available.
                                </div>
                            </div>

                        </div>
                        <div class="form-group">
                            <p class="ca-label">Username</p>
                            <input [ngClass]="{ 'is-invalid': (burf.username.dirty || brandUserRegistrationFormSubmitted) && burf.username.errors }"
                                   class="form-control" formControlName="username" required type="text">
                            <div *ngIf="(burf.username.dirty || brandUserRegistrationFormSubmitted) && (burf.username.errors) "
                                 class="invalid-feedback">
                                <div *ngIf="burf.username.errors.required">Username is a required field.</div>
                                <div *ngIf="burf.username.errors.minlength">Username should not be less than 3
                                    characters
                                </div>
                                <div *ngIf="burf.username.errors.notUniqueUsername">Username is not available.
                                </div>
                            </div>
                        </div>

                        <!-- <div class="form-group">
                            <p class="ca-label">Full Name</p>
                            <input [ngClass]="{ 'is-invalid': (burf.fullName.dirty || brandUserRegistrationFormSubmitted) && burf.fullName.errors }" class="form-control" formControlName="fullName" id="fullName" required type="url">
                            <div *ngIf="(burf.fullName.dirty || brandUserRegistrationFormSubmitted) && burf.fullName.errors" class="invalid-feedback">
                                <div *ngIf="burf.fullName.errors.required">Hi! What can we call you?</div>
                            </div>
                        </div> -->

                        <div class="row">
                            <div class="form-group col-6">
                                <p class="ca-label">First Name</p>
                                <input [ngClass]="{ 'is-invalid': (burf.firstName.dirty || brandUserRegistrationFormSubmitted) && burf.firstName.errors }"
                                       class="form-control" formControlName="firstName" id="firstName" required
                                       type="url">
                                <div *ngIf="(burf.firstName.dirty || brandUserRegistrationFormSubmitted) && burf.firstName.errors"
                                     class="invalid-feedback">
                                    <div *ngIf="burf.firstName.errors.required">Hi! What can we call you?</div>
                                </div>
                            </div>
                            <div class="form-group col-6">
                                <p class="ca-label">Last Name</p>
                                <input [ngClass]="{ 'is-invalid': (burf.lastName.dirty || brandUserRegistrationFormSubmitted) && burf.lastName.errors }"
                                       class="form-control" formControlName="lastName" id="lastName" required
                                       type="url">
                                <div *ngIf="(burf.lastName.dirty || brandUserRegistrationFormSubmitted) && burf.lastName.errors"
                                     class="invalid-feed">
                                    <div *ngIf="burf.lastName.errors.required" class="desktop-view">You only get to skip
                                        your last name if
                                        you’re famous!
                                    </div>
                                    <div *ngIf="burf.lastName.errors.required" class="mobile-view">You only get to skip
                                        your<br> last name if
                                        you’re famous!
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-6">
                                <p class="ca-label">Title</p>
                                <input [ngClass]="{ 'is-invalid': (burf.title.dirty || brandUserRegistrationFormSubmitted) && burf.title.errors }"
                                       class="form-control" formControlName="title" id="title" type="text">
                            </div>
                            <div class="form-group col-6">
                                <p class="ca-label">Phone</p>
                                <input [ngClass]="{ 'is-invalid': (burf.phone.dirty || brandUserRegistrationFormSubmitted) && burf.phone.errors }"
                                       class="form-control" formControlName="phone" id="phone-number"
                                       mask="(000) 000-0000" type="text">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-6">
                                <p class="ca-label" for="sales_channel">Primary Sales Channel</p>
                                <select [ngClass]="{ 'is-invalid': (burf.sales_channel.dirty || brandUserRegistrationFormSubmitted) && burf.sales_channel.errors }"
                                        class="form-control" formControlName="sales_channel" id="sales_channel"
                                        required>
                                    <option value="">Select One</option>
                                    <option value="direct_to_consumer">Direct to Consumer</option>
                                    <option value="retailer">Retailer</option>
                                    <option value="wholesaler">Wholesaler</option>
                                    <option value="subscription_box">Subscription Box</option>
                                </select>
                                <div *ngIf="(burf.sales_channel.dirty || brandUserRegistrationFormSubmitted) && burf.sales_channel.errors"
                                     class="invalid-feedback">
                                    <div *ngIf="burf.sales_channel.errors.required">Oops, please choose one!
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-6">
                                <p class="ca-label">Website</p>
                                <input [ngClass]="{ 'is-invalid': (burf.website.dirty || brandUserRegistrationFormSubmitted) && burf.website.errors }"
                                       class="form-control" formControlName="website" id="website" type="url">
                                <div *ngIf="(burf.website.dirty || brandUserRegistrationFormSubmitted) && burf.website.errors"
                                     class="invalid-feedback">
                                    <div *ngIf="burf.website.errors.pattern">Please enter a valid website url
                                    </div>
                                    <div *ngIf="burf.website.errors.required">Please enter a website url
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="row foot-nav fixed-bottom">
            <div class="col-12 d-flex justify-content-end align-items-center">
                                <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">
                                               <img alt="error" src="assets/images/ErrorAlert.png"> {{alertMessage}}
                                             </span>

                <span *ngIf="showAlertMessage" class="alertMessage">
                                <img alt="success" src="assets/images/checkMark.png"> {{alertMessage}}
                             </span> &nbsp;&nbsp;&nbsp;
                <button (click)="createBrandAccountOnSubmit()" class="btn btn-primary" type="button">
                    <span>Next</span>
                </button>
            </div>
        </div>
        <!--        <div class="row foot-nav fixed-bottom">-->
        <!--            <div class="col-12 d-flex justify-content-end">-->
        <!--                <button (click)="createBrandAccountOnSubmit()" class="btn btn-primary" type="button">-->
        <!--                    <span>Next</span>-->
        <!--                </button>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>

    <!---------------------------------------- TAB 2 (BRAND SIGNUP) ---------------------------------------------->

    <div *ngIf="showTab == 2" class="create-account-tab tab-2">
        <div class="container">
            <div class="row main-brand-form justify-content-center align-items-center overflow-hidden">
                <div class="col-md-6 col-lg-5">
                    <h4>Tell us about your brand.</h4>
                    <p class="sub-head">Business Details</p>
                    <form [formGroup]="brandBusinessDetailsForm">
                        <div class="form-group">
                            <p class="ca-label">Headquarter Location</p>
                            <input [ngClass]="{ 'is-invalid': (bbdf.headquarterLocation.dirty || brandBusinessDetailsFormSubmitted) && bbdf.headquarterLocation.errors }"
                                   class="form-control" formControlName="headquarterLocation" id="headquarterLocation"
                                   type="text">
                            <div *ngIf="(bbdf.headquarterLocation.dirty || brandBusinessDetailsFormSubmitted) && (bbdf.headquarterLocation.errors) "
                                 class="invalid-feedback">
                                <div *ngIf="bbdf.headquarterLocation.errors.required">Where is your brand located?</div>
                                <div *ngIf="bbdf.headquarterLocation.errors.minlength">Headquarter Location should not
                                    be less than 5 characters
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <p class="ca-label">Tax ID (For Business Verification)</p>
                            <input [ngClass]="{ 'is-invalid': (bbdf.taxID.dirty || brandBusinessDetailsFormSubmitted) && bbdf.taxID.errors }"
                                   class="form-control" formControlName="taxID" id="taxID" mask="00-0000000"
                                   type="text">
                            <div *ngIf="(bbdf.taxID.dirty || brandBusinessDetailsFormSubmitted) && bbdf.taxID.errors"
                                 class="invalid-feedback">
                                <div *ngIf="bbdf.taxID.errors.required">Whoops, we require your business tax ID!</div>
                                <div *ngIf="bbdf.taxID.errors.minlength">tax ID Business should br correct
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-6 onBoarding-ng-select" id="onBoarding-ng-select">
                                <p class="ca-label"> Year Founded</p>
                                <!--                                <input [ngClass]="{ 'is-invalid': (bbdf.yearsInBusiness.dirty || brandBusinessDetailsFormSubmitted) && bbdf.yearsInBusiness.errors }"-->
                                <!--                                       appNumbersOnly class="form-control" formControlName="yearsInBusiness"-->
                                <!--                                       id="yearsInBusiness" maxlength="3" type="text">-->
                                <ng-select [clearable]="false" [items]="listOfYears"
                                           formControlName="year_founded">
                                </ng-select>
                                <div *ngIf="(bbdf.year_founded.dirty || brandBusinessDetailsFormSubmitted) && bbdf.year_founded.errors"
                                     class="invalid-feed">
                                    <div *ngIf="bbdf.year_founded.errors.required" class="desktop-view">How long has
                                        your brand been
                                        around?
                                    </div>
                                    <div *ngIf="bbdf.year_founded.errors.required" class="mobile-view">How long has
                                        your brand<br> been
                                        around?
                                    </div>
                                    <div *ngIf="bbdf.year_founded.errors.minlength">Business founded year should be
                                        correct
                                    </div>
                                    <div *ngIf="bbdf.year_founded.errors.pattern">
                                        Only numbers allowed
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-6" style="padding-left: 10px;">
                                <p class="ca-label">Number of Locations</p>
                                <input [ngClass]="{ 'is-invalid': (bbdf.numberOfLocations.dirty || brandBusinessDetailsFormSubmitted) && bbdf.numberOfLocations.errors }"
                                       appNumbersOnly class="form-control" formControlName="numberOfLocations"
                                       id="numberOfLocations" maxlength="4" type="text">
                                <div *ngIf="(bbdf.numberOfLocations.dirty || brandBusinessDetailsFormSubmitted) && bbdf.numberOfLocations.errors"
                                     class="invalid-feedback">
                                    <div *ngIf="bbdf.numberOfLocations.errors.required">How many stores do you have?
                                    </div>
                                    <div *ngIf="bbdf.numberOfLocations.errors.minlength">number Of Locations should br
                                        correct
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-group">
                                <label class="amountBusinessRange mb-2" for="amountBusinessRange">Amount of business
                                    done in-store vs online</label>

                                <input class="bi-slider" formControlName="amountBusinessRange" id="amountBusinessRange"
                                       max="100" min="0" name="range" type="range">

                                <label class="sliderrangelabel" for="amountBusinessRange"
                                       style="float: left;">{{100 - bbdf.amountBusinessRange.value}}%
                                    In-store</label>
                                <label class="sliderrangelabel" for="amountBusinessRange"
                                       style="float: right;">{{bbdf.amountBusinessRange.value}}%
                                    Online</label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="row foot-nav fixed-bottom">
                <div class="col-12 d-flex justify-content-between">
                    <button (click)="tabToggle(1); updateStep1=true" class="btn-alter" type="button">Go Back</button>
                    <div class="d-flex flex-nowrap align-items-center justify-content-end">
                        <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">
                                <img alt="error" src="assets/images/ErrorAlert.png"> {{alertMessage}}
                             </span>
                        <span *ngIf="showAlertMessage" class="alertMessage">
                                <img alt="success" src="assets/images/checkMark.png"> {{alertMessage}}
                             </span> &nbsp;&nbsp;&nbsp;
                        <button (click)="submitBrandBusinessDetail()"
                                [class.disableBtn]="brandBusinessDetailsForm.invalid"
                                class="btn btn-primary" type="button">
                            <span>Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <!--            <div class="row foot-nav fixed-bottom">-->
            <!--                <div class="col-12 d-flex justify-content-end align-items-center">-->
            <!--                    <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">-->
            <!--                                <img alt="error" src="assets/images/ErrorAlert.png"> {{alertMessage}}-->
            <!--                             </span>-->
            <!--                    <span *ngIf="showAlertMessage" class="alertMessage">-->
            <!--                                <img alt="success" src="assets/images/checkMark.png"> {{alertMessage}}-->
            <!--                             </span> &nbsp;&nbsp;&nbsp;-->
            <!--                    <button (click)="submitBrandBusinessDetail()" class="btn btn-primary" type="button">-->
            <!--                        <span>Next</span>-->
            <!--                    </button>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
    </div>

    <!---------------------------------------- TAB 3 (PRODUCT CATEGORY) ---------------------------------------------->

    <div *ngIf="showTab == 3" class="create-account-tab tab-3">
        <form (keydown.enter)="$event.preventDefault()" (keydown.shift.enter)="$event.preventDefault()"
              (ngSubmit)="submitBrandProductCategories()">
            <div class="container-fluid">
                <div class="row main-brand-form product-categories justify-content-between">
                    <!-- Left Section -->
                    <div class="col-lg-8 left-section">
                        <div class="row">
                            <div class="col-lg-12">
                                <h4>What types of products do you carry?</h4>
                                <p class="sub-head">These are your brand's product categories.</p>
                            </div>
                        </div>
                        <!-- CheckBox / Select Items -->
                        <div class="row">
                            <div class="col-md-12 col-lg-7 d-flex justify-content-center d-sm-block">
                                <div class="select-box-wrap">
                                    <!-- CheckBox/Select Item -->
                                    <ng-container *ngFor="let productCategory of productCategories">
                                        <ng-container *ngIf="productCategory.is_predefined" class="col-4">
                                            <div class="select-box">
                                                <input [checked]="productCategory.selected" hidden
                                                       id="pc-{{productCategory.id}}" type="checkbox"/>
                                                <label (click)="toggleBrandCategory(productCategory)"
                                                       for="pc-{{productCategory.id}}">
                                                    <img alt="{{productCategory.title}}"
                                                         src="{{productCategory.image}}">
                                                    {{productCategory.title}}
                                                </label>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <!-- Tags -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group custom-tags" data-select2-id="45">
                                    <p for="name">Add Subcategories<br>
                                        <span class="text-muted">
                                            To add multiple tags, separate tags by comma
                                        </span>
                                    </p>
                                    <div class="input-group mycustom position-relative">
                                        <input #tagInput (keyup)="myText = tagInput.value" (keyup)="onKeyUp($event)"
                                               (keyup.enter)="addCustomProductCategory()" class="form-control rounded-0"
                                               id="ProductCategories" maxlength="50"
                                               placeholder="technology, luxury, skin care" required type="text">
                                        <div class="input-group-prepend">
                                            <input (click)="addCustomProductCategory()" id="ProductCategories1"
                                                   type="button" value="Add">
                                        </div>
                                        <span class="counter">{{tagInput.value.length}}/50</span>
                                        <small *ngIf="tagAdded" class="position-absolute" style="font-size: 14px; right: -45px;
                                                top: 50%;transform: translateY(-50%); color: #293e65">Added</small>
                                    </div>
                                    <form class="form-inline">
                                        <div *ngFor="let productCategory of productCategories" class="mb-3 mt-2">
                                            <div *ngIf="!productCategory.is_predefined && productCategory.selected">
                                                <div class="tag">
                                                    <span>
                                                        {{productCategory.title}}
                                                        <a (click)="removeCustomProductCategory(productCategory.id)">
                                                            &times;
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                        <div class="question-module">
                            <div class="head-question">
                                <div class="img-section">
                                    <div class="inner-image">
                                        <img alt=""
                                             src="{{cdnUrl}}/assets/images/question-mark.png">
                                    </div>
                                    <div class="arrow-sign">

                                    </div>
                                </div>
                                <div class="question-main">
                                    Do any of your products have a minimum age requirement?*
                                </div>
                            </div>
                            <div class="answer-section">
                                <mat-checkbox (change)="ageRequirementSelection(1)" [checked]="ageRequirementSelected === 1" class="example-margin">No, suitable for all
                                    ages
                                </mat-checkbox>
                            </div>
                            <div class="answer-section">
                                <mat-checkbox (change)="ageRequirementSelection(2)" [checked]="ageRequirementSelected === 2" class="example-margin">Yes, must be</mat-checkbox>
                                <div class="type">
                                    <input #ageInput (click)="ageRequirementSelected = 2" [(ngModel)]="ageRequirement"
                                           [ngClass]="{ 'is-invalid': (ageRequirementSelected === 2 && ageInput.value === '')}"
                                           [ngModelOptions]="{standalone: true}"
                                           appNumbersOnly maxlength="2"
                                           placeholder="Age"
                                           type="text"><span>years
                                    old</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Right Section -->
                    <div class="col-lg-4 right-section">
                        <!-- DONUT CHART -->
                        <div class="chart-wrapper">
                            <canvas [chartType]="'doughnut'" [colors]="getProductCategoryChartsColors()" [data]="getProductCategoryChartData()"
                                    [labels]="getProductCategoryChartsLabels()" [legend]="false"
                                    [options]="+brandInfo.getProductCategoryCount(productCategories) > 0 ? chartOption : defaultChartOption" baseChart>
                            </canvas>
                            <div class="donut-center-text">
                                <label>Categories</label>
                                <div [class.text-danger]="total > 100" class="total-allocated">
                                    {{sumOfProductCategoriesPercentages()}}% Allocated
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="!pcLimitExceed">
                            <div *ngIf="total <= 100" [class.text-danger]="total > 99" class="total-left">
                                {{100 - sumOfProductCategoriesPercentages()}}% left to allocate
                            </div>
                            <div *ngIf="total > 100" [class.text-danger]="total < 100" class="total-left">
                                Cannot exceed 100% allocation
                            </div>
                        </ng-container>
                        <ng-container *ngIf="pcLimitExceed">
                            <div class="total-left">
                                Cannot exceed 100% allocation
                            </div>
                        </ng-container>

                        <!-- CHARTS SLIDER -->
                        <div class="sliders-wrapper">
                            <ng-container *ngFor="let productCategory of productCategories">
                                <div *ngIf="productCategory.is_predefined"
                                     class="slider d-flex justify-content-center align-items-center">
                                    <label class="productLabels">{{productCategory.title}}</label>
                                    <div class="custom-chart-sliders">
                                        <input (input)="onProductCategorySliderChange($event, productCategory)"
                                               [ngClass]="!productCategory.selected? 'disable': '' "
                                               [ngStyle]="{'backgroundColor' : productCategory.selected ? productCategory.color : '#E7EDF3',
                                                            'color': productCategory.selected ? '#1E2A40' : '#b8b8b8'}"
                                               class="chart-slider"
                                               id="pc-slider-{{productCategory.id}}" max="100" min="0" step="5" type="range"
                                               value="{{productCategory.percentage}}">
                                    </div>

                                    <label [ngStyle]="{ 'color': productCategory.selected ? '#1E2A40' : 'rgba(30,42,64,0.5)'}"
                                           class="productPerLabels">
                                        <input (keyup)="maxPcValueCheck($event, productCategory)" [(ngModel)]="productCategory.percentage"
                                               [disabled]="!productCategory.selected" [ngModelOptions]="{standalone: true}"
                                               appNumbersOnly max="100" maxlength="{{pcMaxLength}}"
                                               style="background-color: #00000000; border: none; outline: none; width: 33px; text-align: right" type="text">%
                                    </label>
                                    <!-- <input min="0" max="100" #inp class="customSliderInput" type="text" pattern=""
                                        maxlength="3" [disabled]="!productCategory.selected"
                                        [value]="productCategory.percentage" placeholder="0" appNumbersOnly
                                        [ngStyle]="{'borderColor':  (inp.value > 100) ? '#e17400' : '#fffaf7' }"
                                        (keyup)="onProductCategorySliderChange($event.target.value, productCategory)">% -->
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="row foot-nav fixed-bottom">
                    <div class="col-12 d-flex justify-content-between">
                        <button (click)="tabToggle(2)" class="btn-alter" type="button">Go Back</button>
                        <div class="d-flex flex-nowrap align-items-center justify-content-end">
                            <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">
                                <img alt="error" src="assets/images/ErrorAlert.png"> {{alertMessage}}
                             </span>
                            <span *ngIf="showAlertMessage" class="alertMessage">
                                <img alt="success" src="assets/images/checkMark.png"> {{alertMessage}}
                             </span> &nbsp;&nbsp;&nbsp;
                            <button [class.disableBtn]="total !== 100 || ageRequirementSelected < 1" class="btn btn-primary"
                                    type="submit">Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <!------------------------------------ TAB 4 (PRODUCT CATEGORY DETAIL) ------------------------------------------>

    <div *ngIf="showTab == 4" class="create-account-tab tab-4">
        <form (keydown.enter)="$event.preventDefault()" (keydown.shift.enter)="$event.preventDefault()"
              (ngSubmit)="submitBrandProductCategoriesDetails()">
            <div class="container">
                <div class="row main-brand-form justify-content-center">
                    <div class="col-md-7">
                        <h4>Add Price and Product Tags</h4>
                        <p class="para">These are your brand’s product categories.</p>
                        <!-- Product Category Item -->
                        <ng-container *ngFor="let productCategory of productCategories; let index = index;">
                            <div *ngIf="productCategory.selected && productCategory.is_predefined"
                                 class="row product-price no-gutters">
                                <div class="col-2 col-xl-1 text-center mobile-show">
                                    <img alt="{{productCategory.title}}" class="prod-img"
                                         src="{{productCategory.image}}">
                                    <h5 class="h5-desktop">{{productCategory.title}}</h5>
                                </div>
                                <div class="col-12 h5-desktop">
                                    <!-- Product Title Here -->
                                    <div class="row no-gutters">
                                        <div class="col-12 p-lg-0">
                                            <span class="text-danger" id="pc-error-{{productCategory.id}}"></span>
                                            <h6>Price Range</h6>
                                        </div>
                                    </div>
                                    <!-- Product Price -->
                                    <div class="row no-gutters">
                                        <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                            <input appNumbersOnly autocomplete="off" class="form-control"
                                                   id="min-price-{{productCategory.id}}-d" min="0" placeholder="$ MIN"
                                                   max="999999"
                                                   oninput="this.value = this.value.slice(0, 6)"
                                                   type="number" value="{{productCategory.min_price}}">

                                            <input appNumbersOnly autocomplete="off" class="form-control"
                                                   id="max-price-{{productCategory.id}}-d" min="0" placeholder="$ MAX"
                                                   max="999999"
                                                   oninput="this.value = this.value.slice(0, 6)"
                                                   type="number" value="{{productCategory.max_price}}">

                                            <input appNumbersOnly autocomplete="off" class="form-control"
                                                   id="avg-price-{{productCategory.id}}-d" min="0" placeholder="$ AVG"
                                                   max="999999"
                                                   oninput="this.value = this.value.slice(0, 6)"
                                                   type="number" value="{{productCategory.avg_price}}">
                                        </div>
                                    </div>
                                    <!-- Tags -->
                                    <div class="row pr-2 no-gutters pr-md-0">
                                        <div class="form-group custom-tags position-relative">
                                            <p class="text-muted">
                                                To add multiple tags, separate tags by comma
                                            </p>
                                            <div class="input-group mycustom position-relative">
                                                <input #tagInput (keyup)="onKeyUpCategory($event,productCategory.id)"
                                                       (keyup.enter)="addProductCategoryDetailsTags(productCategory.id)"
                                                       class="form-control rounded-0"
                                                       id="productDetail-{{productCategory.id}}"
                                                       placeholder="{{productCategory.tags_placeholders.join(', ') + ', etc.'}}"
                                                       required type="text">
                                                <div class="input-group-prepend">
                                                    <input (click)="addProductCategoryDetailsTags(productCategory.id)"
                                                           type="button" value="Add">
                                                </div>
                                                <small class="position-absolute productDetail-tag-{{productCategory.id}}"
                                                       style="font-size: 14px; right: -45px;top: 50%;transform: translateY(-50%); color: #293e65; display: none">Added</small>
                                            </div>

                                            <form class="form-inline">
                                                <ng-container *ngFor="let tag of productCategory.tags">
                                                    <div class="tag mb-3 mt-2">
                                                        <span>
                                                            {{tag}}
                                                            <a (click)="removeProductCategoryTags(productCategory.id, tag)">
                                                                &times;
                                                            </a>
                                                        </span>
                                                    </div>
                                                </ng-container>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-10 h5-mobile">
                                    <!-- Product Title Here -->
                                    <div class="row no-gutters">
                                        <div class="col-12 p-lg-0">
                                            <h5>{{productCategory.title}}</h5>
                                            <span class="text-danger" id="pc-error-{{productCategory.id}}"></span>
                                            <h6>Price Range</h6>
                                        </div>
                                    </div>
                                    <!-- Product Price -->
                                    <div class="row no-gutters">
                                        <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                            <input appNumbersOnly autocomplete="off" class="form-control"
                                                   id="min-price-{{productCategory.id}}" min="0" placeholder="$ MIN"
                                                   max="999999"
                                                   oninput="this.value = this.value.slice(0, 6)"
                                                   type="number" value="{{productCategory.min_price}}">

                                            <input appNumbersOnly autocomplete="off" class="form-control"
                                                   id="max-price-{{productCategory.id}}" min="0" placeholder="$ MAX"
                                                   max="999999"
                                                   oninput="this.value = this.value.slice(0, 6)"
                                                   type="number" value="{{productCategory.max_price}}">

                                            <input appNumbersOnly autocomplete="off" class="form-control"
                                                   id="avg-price-{{productCategory.id}}" min="0" placeholder="$ AVG"
                                                   max="999999"
                                                   oninput="this.value = this.value.slice(0, 6)"
                                                   type="number" value="{{productCategory.avg_price}}">
                                        </div>
                                    </div>
                                    <!-- Tags -->
                                    <div class="row pr-2 no-gutters pr-md-0">
                                        <div class="form-group custom-tags">
                                            <p class="text-muted">
                                                To add multiple tags, separate tags by comma
                                            </p>
                                            <div class="input-group mycustom position-relative">
                                                <input #tagInput (keyup)="onKeyUpCategory($event,productCategory.id)"
                                                       (keyup.enter)="addProductDetailsTags(productCategory.id)"
                                                       class="form-control rounded-0"
                                                       id="DetailTag-{{productCategory.id}}"
                                                       placeholder="{{productCategory.tags_placeholders.join(', ')}}"
                                                       required type="text">
                                                <div class="input-group-prepend">
                                                    <input (click)="addProductDetailsTags(productCategory.id)"
                                                           type="button" value="Add">
                                                </div>
                                                <small class="position-absolute productDetail-tag-{{productCategory.id}}"
                                                       style="font-size: 14px; right: -45px;top: 50%;transform: translateY(-50%); color: #293e65; display: none">Added</small>
                                            </div>

                                            <form class="form-inline">
                                                <ng-container *ngFor="let tag of productCategory.tags">
                                                    <div class="tag mb-3 mt-2">
                                                        <span>
                                                            {{tag}}
                                                            <a (click)="removeProductCategoryTags(productCategory.id, tag)">
                                                                &times;
                                                            </a>
                                                        </span>
                                                    </div>
                                                </ng-container>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="row foot-nav fixed-bottom">
                    <div class="col-12 d-flex justify-content-between">
                        <button (click)="tabToggle(3); autoSetProductCategories = true" class="btn-alter" type="button">Go Back</button>
                        <div class="d-flex flex-nowrap align-items-center">
                            <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">
                                 <img alt="error" src="assets/images/ErrorAlert.png"> {{alertMessage}}
                             </span>
                            <span *ngIf="showAlertMessage" class="alertMessage">
                               <img alt="success" src="assets/images/checkMark.png"> {{alertMessage}}
                             </span> &nbsp;&nbsp;&nbsp;
                            <button class="btn btn-primary" type="submit">Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <!---------------------------------------- TAB 5 (PRODUCT VIBES)---------------------------------------------->

    <div *ngIf="showTab == 5" class="create-account-tab tab-5">
        <form (keydown.enter)="$event.preventDefault()" (keydown.shift.enter)="$event.preventDefault()"
              (ngSubmit)="submitBrandVibes()">
            <div class="container-fluid">
                <div class="row main-brand-form product-categories justify-content-between">
                    <!-- Left Section -->
                    <div class="col-lg-8 left-section">
                        <div class="row">
                            <div class="col-lg-12">
                                <h4>How would you describe your brand’s aesthetic?</h4>
                                <p class="sub-head">This is your brand’s style and overall vibe.</p>
                            </div>
                        </div>
                        <!-- CheckBox / Select Items -->
                        <div class="row">
                            <div class="col-md-12 col-lg-7 d-flex justify-content-center d-sm-block">
                                <div class="select-box-wrap">
                                    <!-- CheckBox/Select Item -->
                                    <ng-container *ngFor="let vibe of vibes">
                                        <ng-container *ngIf="vibe.is_predefined" class="col-4">
                                            <div class="select-box">
                                                <input [checked]="vibe.selected" hidden id="v-{{vibe.id}}"
                                                       type="checkbox"/>
                                                <label (click)="toggleBrandVibe(vibe)" for="v-{{vibe.id}}">
                                                    <img alt="{{vibe.title}}" src="{{vibe.image}}">
                                                    {{vibe.title}}
                                                </label>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <!-- Tags -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group custom-tags" data-select2-id="45">
                                    <p for="name">Add Additional Brand Vibes<br>
                                        <span class="text-muted">
                                            To add multiple tags, separate tags by comma
                                        </span>
                                    </p>
                                    <div class="input-group mycustom position-relative">
                                        <input #tagInput (keyup)="vibeTxt = tagInput.value"
                                               (keyup)="onKeyUpVibe($event)" (keyup.enter)="addCustomVibeTags()"
                                               class="form-control rounded-0" id="vibeTags" required type="text">
                                        <div class="input-group-prepend">
                                            <input (click)="addCustomVibeTags()" id="vibe2" type="button" value="Add">
                                        </div>
                                        <small *ngIf="tagAdded" class="position-absolute" style="font-size: 14px; right: -45px;
                                                top: 50%;transform: translateY(-50%); color: #293e65">Added</small>
                                    </div>

                                    <form class="form-inline">
                                        <div *ngFor="let vibe of vibes" class="mb-3 mt-2">
                                            <div *ngIf="!vibe.is_predefined && vibe.selected">
                                                <div class="tag">
                                                    <span>
                                                        {{vibe.title}}
                                                        <a (click)="removeVibesTags(vibe.id)">
                                                            &times;
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Right Section -->
                    <div class="col-lg-4 right-section">
                        <!-- DONUT CHART -->
                        <div class="chart-wrapper">
                            <canvas [chartType]="'doughnut'" [colors]="getVibesChartsColors()"
                                    [data]="getVibesChartData()" [labels]="getVibesChartsLabels()" [legend]="false"
                                    [options]="+brandInfo.getVibesCount(vibes) > 0 ? chartOption : defaultChartOption" baseChart>
                            </canvas>
                            <div class="donut-center-text">
                                <label>Vibes</label>
                                <div [class.text-danger]="vibesTotalPercentage > 100" class="total-allocated">
                                    {{sumOfVibesPercentages()}}% Allocated
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="!vibLimitExceed">
                            <div *ngIf="vibesTotalPercentage <= 100" [class.text-danger]="vibesTotalPercentage > 99"
                                 class="total-left">
                                {{100 - sumOfVibesPercentages()}}% left to allocate
                            </div>
                            <div *ngIf="vibesTotalPercentage > 100" [class.text-danger]="vibesTotalPercentage < 100"
                                 class="total-left">
                                Cannot exceed 100% allocation
                            </div>
                        </ng-container>
                        <ng-container *ngIf="vibLimitExceed">
                            <div class="total-left">
                                Cannot exceed 100% allocation
                            </div>
                        </ng-container>
                        <!-- CHARTS SLIDER -->
                        <div class="sliders-wrapper">
                            <ng-container *ngFor="let vibe of vibes">
                                <div *ngIf="vibe.is_predefined"
                                     class="slider d-flex justify-content-center align-items-center">
                                    <label class="productLabels">{{vibe.title}}</label>
                                    <div class="custom-chart-sliders">
                                        <input (input)="onVibesSliderChange($event, vibe)"
                                               [ngClass]="!vibe.selected? 'disable': '' "
                                               [ngStyle]="{'backgroundColor' : vibe.selected ? vibe.color : '#E7EDF3',
                                                            'color': vibe.selected ? '#1E2A40' : '#b8b8b8'}"
                                               class="chart-slider" max="100" min="0" step="5" type="range"
                                               value="{{vibe.percentage}}">

                                    </div>

                                    <label [ngStyle]="{ 'color': vibe.selected ? '#1E2A40' : 'rgba(30,42,64,0.5)'}"
                                           class="productPerLabels">
                                        <input (keyup)="maxVbValueCheck($event, vibe)" [(ngModel)]="vibe.percentage"
                                               [disabled]="!vibe.selected" [ngModelOptions]="{standalone: true}"
                                               appNumbersOnly max="100" maxlength="{{vbMaxLength}}"
                                               style="background-color: #00000000; border: none; outline: none; width: 33px; text-align: right" type="text">%
                                    </label>

                                    <!--                                    <label [ngStyle]="{ 'color': vibe.selected ? '#1E2A40' : 'rgba(30,42,64,0.5)'}"-->
                                    <!--                                           class="productPerLabels">{{vibe.percentage | number : '1.0-0'}}%</label>-->
                                    <!--                                    <input #inp2 (keyup)="onVibesSliderChange($event.target.value, vibe)" [disabled]="!vibe.selected"-->
                                    <!--                                           [value]="vibe.percentage"-->
                                    <!--                                           appNumbersOnly class=""-->
                                    <!--                                           max="100" maxlength="3" min="0"-->
                                    <!--                                           pattern="" placeholder="0"-->
                                    <!--                                           style="background-color: #00000000; border: none; outline: none; width: 30px; text-align: right"-->
                                    <!--                                           type="text">%-->
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="row foot-nav fixed-bottom">
                    <div class="col-12 d-flex justify-content-between">
                        <button (click)="tabToggle(4)" class="btn-alter" type="button">Go Back</button>
                        <div class="d-flex flex-nowrap align-items-center">
                            <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">
                                 <img alt="error" src="assets/images/ErrorAlert.png"> {{alertMessage}}
                             </span>
                            <span *ngIf="showAlertMessage" class="alertMessage">
                                <img alt="success" src="assets/images/checkMark.png"> {{alertMessage}}
                             </span> &nbsp;&nbsp;&nbsp;
                            <button [class.disableBtn]="vibesTotalPercentage !== 100" class="btn btn-primary"
                                    type="submit">Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <!---------------------------------------- TAB 6 (PRODUCT VALUES)---------------------------------------------->

    <div *ngIf="showTab == 6" class="create-account-tab tab-6">
        <form (keydown.enter)="$event.preventDefault()" (keydown.shift.enter)="$event.preventDefault()"
              (ngSubmit)="submitBrandValues()">
            <div class="container-fluid">
                <div class="row main-brand-form product-categories justify-content-between">
                    <!-- Left Section -->
                    <div class="col-lg-8 left-section">
                        <div class="row">
                            <div class="col-lg-12">
                                <h4>What is your brand known for?</h4>
                                <p class="sub-head">This is what keeps your customers coming back. Tell us what you
                                    think & we will let you know if your customers agree.</p>
                            </div>
                        </div>
                        <!-- CheckBox / Select Items -->
                        <div class="row">
                            <div class="col-md-12 col-lg-7 d-flex justify-content-center d-sm-block">
                                <div class="select-box-wrap">
                                    <!-- CheckBox/Select Item -->
                                    <ng-container *ngFor="let value of values">
                                        <ng-container *ngIf="value.is_predefined" class="col-4">
                                            <div class="select-box">
                                                <input [checked]="value.selected" hidden id="v-{{value.id}}"
                                                       type="checkbox"/>
                                                <label (click)="toggleBrandValue(value)" for="v-{{value.id}}">
                                                    <img alt="{{value.title}}" src="{{value.image}}">
                                                    {{value.title}}
                                                </label>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <!-- Tags -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group custom-tags" data-select2-id="45">
                                    <p for="name">Anything else?<br>
                                        <span class="text-muted">
                                            To add multiple tags, separate tags by comma
                                        </span>
                                    </p>
                                    <div class="input-group mycustom position-relative">
                                        <input #tagInput (keyup)="valueTxt = tagInput.value"
                                               (keyup)="onKeyUpValue($event)" (keyup.enter)="addCustomValueTags()"
                                               class="form-control rounded-0" id="valueTags"
                                               placeholder="female founded, family friendly, transparent" required
                                               type="text">
                                        <div class="input-group-prepend">
                                            <input (click)="addCustomValueTags()" id="value2" type="button" value="Add">
                                        </div>
                                        <small *ngIf="tagAdded" class="position-absolute" style="font-size: 14px; right: -45px;
                                                top: 50%;transform: translateY(-50%); color: #293e65">Added</small>
                                    </div>

                                    <form class="form-inline">
                                        <div *ngFor="let value of values" class="mb-3 mt-2">
                                            <div *ngIf="!value.is_predefined && value.selected">
                                                <div class="tag">
                                                    <span>
                                                        {{value.title}}
                                                        <a (click)="removeValuesTags(value.id)">
                                                            &times;
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Right Section -->
                    <div class="col-lg-4 right-section">
                        <!-- DONUT CHART -->
                        <div class="chart-wrapper">
                            <canvas [chartType]="'doughnut'" [colors]="getBrandValuesChartsColors()"
                                    [data]="getBrandValuesChartData()" [labels]="getBrandValuesChartsLabels()"
                                    [legend]="false"
                                    [options]="+brandInfo.getValuesCount(values) > 0 ? chartOption : defaultChartOption" baseChart>
                            </canvas>
                            <div class="donut-center-text">
                                <label>Values</label>
                                <div [class.text-danger]="totalBrandValuesPer > 100" class="total-allocated">
                                    {{sumOfBrandValuesPercentages()}}% Allocated
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="!valLimitExceed">
                            <div *ngIf="totalBrandValuesPer <= 100" [class.text-danger]="totalBrandValuesPer > 99"
                                 class="total-left">
                                {{100 - sumOfBrandValuesPercentages()}}% left to allocate
                            </div>
                            <div *ngIf="totalBrandValuesPer > 100" [class.text-danger]="totalBrandValuesPer < 100"
                                 class="total-left">
                                Cannot exceed 100% allocation
                            </div>
                        </ng-container>
                        <ng-container *ngIf="valLimitExceed">
                            <div class="total-left">
                                Cannot exceed 100% allocation
                            </div>
                        </ng-container>
                        <!-- CHARTS SLIDER -->
                        <div class="sliders-wrapper">
                            <ng-container *ngFor="let brandValue of values">
                                <div *ngIf="brandValue.is_predefined"
                                     class="slider d-flex justify-content-center align-items-center">
                                    <label class="productLabels">{{brandValue.title}}</label>
                                    <div class="custom-chart-sliders">
                                        <input (input)="onBrandValuesSliderChange($event, brandValue)"
                                               [ngClass]="!brandValue.selected? 'disable': ''" [ngStyle]="{'backgroundColor' : brandValue.selected ? brandValue.color : '#E7EDF3',
                                                            'color': brandValue.selected ? '#1E2A40' : '#b8b8b8'}"
                                               class="chart-slider" max="100" min="0" step="5" type="range"
                                               value="{{brandValue.percentage}}">
                                    </div>

                                    <label [ngStyle]="{ 'color': brandValue.selected ? '#1E2A40' : 'rgba(30,42,64,0.5)'}"
                                           class="productPerLabels">
                                        <input (keyup)="maxVlValueCheck($event, brandValue)" [(ngModel)]="brandValue.percentage"
                                               [disabled]="!brandValue.selected" [ngModelOptions]="{standalone: true}"
                                               appNumbersOnly max="100" maxlength="{{valMaxLength}}"
                                               style="background-color: #00000000; border: none; outline: none; width: 33px; text-align: right" type="text">%
                                    </label>

                                    <!--                                    <label [ngStyle]="{'color': brandValue.selected ? '#1E2A40' : 'rgba(30,42,64,0.5)'}"-->
                                    <!--                                           class="productPerLabels">{{brandValue.percentage | number : '1.0-0'}}-->
                                    <!--                                        %</label>-->
                                    <!--                                    <label [ngStyle]="{'color': brandValue.selected ? '#1E2A40' : 'rgba(30,42,64,0.5)'}"-->
                                    <!--                                           class="productPerLabels">-->
                                    <!--                                        <input #inp3 (keyup)="onBrandValuesSliderChange($event.target.value, brandValue)" [disabled]="!brandValue.selected"-->
                                    <!--                                               [value]="brandValue.percentage" appNumbersOnly-->
                                    <!--                                               max="100" maxlength="3" min="0"-->
                                    <!--                                               pattern="" placeholder="0"-->
                                    <!--                                               style="background-color: #00000000; border: none; outline: none; width: 30px; text-align: right"-->
                                    <!--                                               type="text">%-->
                                    <!--                                    </label>-->


                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="row foot-nav fixed-bottom">
                    <div class="col-12 d-flex justify-content-between">
                        <button (click)="tabToggle(5); autoSetVibes = true" class="btn-alter" type="button">Go Back</button>
                        <div class="d-flex flex-nowrap align-items-center">
                            <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">
                                  <img alt="error" src="assets/images/ErrorAlert.png"> {{alertMessage}}
                             </span>
                            <span *ngIf="showAlertMessage" class="alertMessage">
                                <img alt="success" src="assets/images/checkMark.png"> {{alertMessage}}
                             </span> &nbsp;&nbsp;&nbsp;
                            <button [class.disableBtn]="totalBrandValuesPer !== 100" class="btn btn-primary"
                                    type="submit">Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <!---------------------------------------- TAB 7 (SOCIAL CAUSE)---------------------------------------------->

    <div *ngIf="showTab == 7" class="create-account-tab tab-7">
        <form (keydown.enter)="$event.preventDefault()" (keydown.shift.enter)="$event.preventDefault()"
              (ngSubmit)="submitBrandSocialCauses()">
            <div class="container">
                <div class="row main-brand-form">
                    <div class="col-md-10 col-lg-8">
                        <div class="row">
                            <div class="col-lg-12">
                                <h4>Does your brand giveback?</h4>
                                <p class="sub-head">These are your brand’s interest in giving back and social causes.
                                </p>
                            </div>
                        </div>
                        <!-- Social Causes Checkboxes -->
                        <div class="row no-gutters mt-5">
                            <!-- SINGLE CHECK BOX ITEM -->
                            <ng-container *ngFor="let socialCause of socialCauses">
                                <div *ngIf="socialCause.is_predefined && !socialCause.is_other"
                                     class="col-6 col-md-4 p-0 mb-4">
                                    <label class="social-chkbox">{{socialCause.title}}
                                        <input (change)="toggleBrandSocialCause(socialCause)"
                                               [checked]="socialCause.selected" type="checkbox">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </ng-container>
                            <!-- Other Social Cause Input -->
                            <ng-container *ngFor="let socialCause of socialCauses">
                                <div *ngIf="!socialCause.is_predefined && socialCause.is_other && socialCause.selected"
                                     class="col-6 col-md-4 p-0 mb-4">
                                    <label class="social-chkbox">{{socialCause.title}}
                                        <input (change)="toggleBrandSocialCause(socialCause)"
                                               [checked]="socialCause.selected" type="checkbox">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </ng-container>
                            <div *ngIf="!isOtherSocialCauseShown" class="col-6 col-md-4 p-0 mb-4">
                                <label class="social-chkbox">Other
                                    <input (click)="toggleOtherSocialCauseShow()" type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div *ngIf="isOtherSocialCauseShown" class="col-6 col-md-4 p-0 mb-4">
                                <input (focusout)="addCustomSocialOthers($event)" class="form-control"
                                       id="socialCauseother" placeholder="Enter Custom Social Cause" type="text">
                            </div>

                        </div>
                        <!-- Tags -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group custom-tags" data-select2-id="45">
                                    <p for="name">Add Specific Causes<br>
                                        <span class="text-muted">
                                            To add multiple tags, separate tags by comma
                                        </span>
                                    </p>
                                    <div class="input-group mycustom position-relative">
                                        <input #tagInput (keyup)="scsTxt = tagInput.value"
                                               (keyup)="onKeyUpSocial($event)" (keyup.enter)="addCustomSocialTags()"
                                               class="form-control rounded-0" id="socialTags"
                                               placeholder="St. Judes, Doctors without Borders, Everytown" required
                                               type="text">
                                        <div class="input-group-prepend">
                                            <input (click)="addCustomSocialTags()" id="socail2" type="button"
                                                   value="Add">
                                        </div>
                                        <small *ngIf="tagAdded" class="position-absolute" style="font-size: 14px; right: -45px;
                                                top: 50%;transform: translateY(-50%); color: #293e65">Added</small>
                                    </div>

                                    <form class="form-inline">
                                        <div *ngFor="let socialCause of socialCauses" class="mb-3 mt-2">
                                            <div *ngIf="!socialCause.is_predefined && !socialCause.is_other && socialCause.selected">
                                                <div class="tag">
                                                    <span>
                                                        {{socialCause.title}}
                                                        <a (click)="removeSocialCausesTags(socialCause.id)"
                                                           style="margin-left: 14px">
                                                            &times;
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row foot-nav fixed-bottom">
                    <div class="col-12 d-flex justify-content-between">
                        <button (click)="tabToggle(6); autoSetValues=true" class="btn-alter" type="button">Go Back</button>
                        <div class="d-flex flex-nowrap align-items-center">
                            <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">
                                  <img alt="error" src="assets/images/ErrorAlert.png"> {{alertMessage}}
                             </span>
                            <span *ngIf="showAlertMessage" class="alertMessage">
                                <img alt="success" src="assets/images/checkMark.png"> {{alertMessage}}
                             </span> &nbsp;&nbsp;&nbsp;
                            <button class="btn btn-primary" type="submit">Next</button>
                        </div>
                    </div>
                </div>
            </div>

        </form>

    </div>

    <!---------------------------------------- TAB 8 (BRAND DESCRIPTION)---------------------------------------------->

    <div *ngIf="showTab == 8" class="create-account-tab tab-8">
        <form (keydown.enter)="$event.preventDefault()" (keydown.shift.enter)="$event.preventDefault()"
              (ngSubmit)="submitBrandDescription()">
            <div class="container">
                <div class="row main-brand-form">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-lg-12">
                                <h4>Brand Description</h4>
                                <p class="sub-head">
                                    Give us your best elevator pitch.
                                </p>
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div class="col-md-10">
                                <div class="form-group mb-0">
                                    <textarea #brandDescription [(ngModel)]="brandInfo.description"
                                              [ngModelOptions]="{standalone: true}"
                                              class="form-control" id="branddescriptioncomment" maxlength="415"
                                              onkeyup="countChar415(this)" placeholder="Write here..."
                                              rows="5">{{brandInfo.description || ''}}</textarea>
                                </div>
                                <label id="charNum415">
                                    {{brandInfo.description ? brandInfo.description.length : 0}}/415 Characters
                                </label>
                            </div>
                        </div>
                        <!-- Tags -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group custom-tags" data-select2-id="45">
                                    <p for="name">What words are often used to describe your brand?<br>
                                        <span class="text-muted">
                                            To add multiple tags, separate tags by comma
                                        </span>
                                    </p>
                                    <div class="input-group mycustom position-relative">
                                        <input #tagInput (keyup)="desTxt = tagInput.value" (keyup)="onKeyUpDesc($event)"
                                               (keyup.enter)="addBrandDescriptionTags()" class="form-control rounded-0"
                                               id="brandDescriptionTags" placeholder="hip, cool, groundbreaking"
                                               required type="text" onkeyup="countChar35(this)">
                                        <div class="input-group-prepend">
                                            <input (click)="addBrandDescriptionTags()" id="PriceRang2" type="button"
                                                   value="Add">
                                        </div>
                                        <small *ngIf="tagAdded" class="position-absolute" style="font-size: 14px; right: -45px;
                                                top: 50%;transform: translateY(-50%); color: #293e65">Added</small>
                                    </div>

                                    <form class="form-inline">
                                        <div *ngFor="let brandTag of brandInfo.tags" class="mb-3 mt-2">
                                            <div *ngIf="brandTag">
                                                <div class="tag">
                                                    <span>
                                                        {{brandTag}}&nbsp;
                                                        <a (click)="removeBrandDescriptionTags(brandTag)"
                                                           style="margin-left: 14px">
                                                            &times;
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row foot-nav fixed-bottom">
                    <div class="col-12 d-flex justify-content-between">
                        <button (click)="tabToggle(7)" class="btn-alter" type="button">Go Back</button>
                        <div class="d-flex flex-nowrap align-items-center">
                            <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">
                                  <img alt="error" src="assets/images/ErrorAlert.png"> {{alertMessage}}
                             </span>
                            <span *ngIf="showAlertMessage" class="alertMessage">
                                <img alt="success" src="assets/images/checkMark.png"> {{alertMessage}}
                             </span> &nbsp;&nbsp;&nbsp;
                            <button [class.disableBtn]="brandInfo.description?.length < 3" class="btn btn-primary"
                                    type="submit">Next
                            </button>


                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <!---------------------------------------- TAB 9 (CUSTOMER PROFILE)---------------------------------------------->

    <div *ngIf="showTab == 9" class="create-account-tab tab-9">
        <form (keydown.enter)="$event.preventDefault()" (keydown.shift.enter)="$event.preventDefault()"
              (ngSubmit)="submitCustomerProfile()">
            <div class="container">
                <div class="row main-brand-form">
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-lg-12">
                                <h4>Build your customer profile</h4>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Slider item -->
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <h5>
                                        Male to Female Ratio
                                    </h5>
                                    <div class="custom-slider">
                                        <ng5-slider [(value)]="brandInfo.brandcustomerprofile.male_to_female_ratio"
                                                    [options]="singleSliderRange" id="male-to-female-ratio">
                                        </ng5-slider>
                                    </div>
                                    <label for="male-to-female-ratio" style="float: left;">
                                        {{100 - brandInfo.brandcustomerprofile.male_to_female_ratio}}% Male
                                    </label>
                                    <label for="male-to-female-ratio" style="float: right;">
                                        {{brandInfo.brandcustomerprofile.male_to_female_ratio}}% Female
                                    </label>
                                </div>
                            </div>

                            <!-- Slider Item -->
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <h5>
                                        Customer Age Range
                                    </h5>
                                    <div class="custom-slider">
                                        <ng5-slider [(highValue)]=brandInfo.brandcustomerprofile.max_age
                                                    [(value)]=brandInfo.brandcustomerprofile.min_age
                                                    [options]="ageRangeSliderRange" id="customer-age-range">
                                        </ng5-slider>
                                    </div>
                                    <label for="customer-age-range" style="float: left;">
                                        {{brandInfo.brandcustomerprofile.min_age}} years old
                                    </label>
                                    <label for="customer-age-range" style="float: right;">
                                        {{brandInfo.brandcustomerprofile.max_age}} years old
                                    </label>
                                </div>
                            </div>

                        </div>
                        <!-- Tags -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group custom-tags" data-select2-id="45">
                                    <p for="name">Customer Activities, Interests, and Hobbies<br>
                                        <span class="text-muted">
                                            To add multiple tags, separate tags by comma
                                        </span>
                                    </p>
                                    <div class="input-group mycustom position-relative">
                                        <input #tagInput1 (keyup)="pubTxt = tagInput1.value"
                                               (keyup)="onKeyUpInterest($event)"
                                               (keyup.enter)="addCustomerInterestsTags()" class="form-control rounded-0"
                                               id="customerinterest"
                                               placeholder="politics, reading, skiing, yoga, painting" required
                                               type="text">
                                        <div class="input-group-prepend">
                                            <input (click)="addCustomerInterestsTags()" id="customerinterest2"
                                                   type="button" value="Add">
                                        </div>
                                        <small class="position-absolute" id="customerInterestTags" style="font-size: 14px; right: -45px;
                                                top: 50%;transform: translateY(-50%); color: #293e65; display: none">Added</small>
                                    </div>

                                    <form class="form-inline">
                                        <div *ngFor="let customerInterest of brandInfo.brandcustomerprofile.customer_interests"
                                             class="mb-3 mt-2">
                                            <div *ngIf="customerInterest">
                                                <div class="tag">
                                                    <span>
                                                        {{customerInterest}}
                                                        <a (click)="removeCustomerInterestTag(customerInterest)"
                                                           style="margin-left: 14px">
                                                            &times;
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <!-- Tags -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group custom-tags" data-select2-id="45">
                                    <p for="name">Customer Life Stages<br>
                                        <span class="text-muted">
                                            To add multiple tags, separate tags by comma
                                        </span>
                                    </p>
                                    <div class="input-group mycustom position-relative">
                                        <input #tagInput2 (keyup)="cusDesTxt = tagInput2.value"
                                               (keyup)="onKeyUpDescriptionTags($event)"
                                               (keyup.enter)="addCustomerDescriptionTags()"
                                               class="form-control rounded-0" id="customerDescription"
                                               placeholder="married, creative, professional" required
                                               type="text">
                                        <div class="input-group-prepend">
                                            <input (click)="addCustomerDescriptionTags()" id="customerDescription2"
                                                   type="button" value="Add">
                                        </div>
                                        <small class="position-absolute" id="customerLifeTags" style="font-size: 14px; right: -45px;
                                                top: 50%;transform: translateY(-50%); color: #293e65; display: none">Added</small>
                                    </div>
                                    <form class="form-inline">
                                        <div *ngFor="let customerDescription of brandInfo.brandcustomerprofile.customer_life_stages"
                                             class="mb-3 mt-2">
                                            <div *ngIf="customerDescription">
                                                <div class="tag">
                                                    <span>
                                                        {{customerDescription}}
                                                        <a (click)="removeCustomerDescriptionTag(customerDescription)"
                                                           style="margin-left: 14px">
                                                            &times;
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row foot-nav fixed-bottom">
                    <div class="col-12 d-flex justify-content-between">
                        <button (click)="tabToggle(8)" class="btn-alter" type="button">Go Back</button>
                        <div class="d-flex flex-nowrap align-items-center">
                            <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">
                                  <img alt="error" src="assets/images/ErrorAlert.png"> {{alertMessage}}
                             </span>
                            <span *ngIf="showAlertMessage" class="alertMessage">
                                <img alt="success" src="assets/images/checkMark.png">{{alertMessage}}
                             </span> &nbsp;&nbsp;&nbsp;
                            <button class="btn btn-primary" type="submit">Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <!-------------------------------------- TAB 10 (BRAND VIDEO UPLOAD)-------------------------------------------->

    <div *ngIf="showTab == 10" class="create-account-tab tab-10">
        <form (ngSubmit)="uploadBrandStory()">
            <div class="container">
                <div class="row main-brand-form">
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-12 ">
                                <div class="hi-image">
                                    <img alt="PeopleHello"
                                         src="https://tailorie-common-static.s3.us-east-2.amazonaws.com/images/emails/welcome-email/PeopleHello%402x.png">
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <h4>Upload Your Brand Intro Video</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-9 side-text">
                                <h2>Share a 1-2 minute video introducing your brand to the Tailorie community.</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 heading">
                                <h2>
                                    Stories Connect & Authenticity is Magic!
                                </h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-9 sub-heading">
                                <p>
                                    In your Intro Video, the primary video on your profile, we want you to share your
                                    brand’s story. Focus on sharing your brand’s “why” — why do you exist? Tell us the
                                    story of what led you there! Engage your audience by being yourself.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <label class="btn7 show-on-desktop">
                                <span>Upload your brand video </span>
                                <input #selectVideo (change)="directUpload($event)" accept="video/*" hidden id="introVideoUpload" type="file">
                            </label>

                            <label class="btn7 show-on-mobile">
                                <span>Upload your brand video </span>
                                <input #selectVideo (change)="directUpload($event)" accept="video/*" hidden id="directUpload" type="file">
                            </label>

                            <button (click)="uploadStoryLater()" class="btn6" type="button">
                                Upload later
                            </button>
                        </div>
                        <div class="col-lg-8 pl-0">
                            <p class="terms">
                                *We require a brand intro video to be uploaded before your profile becomes active on the
                                Tailorie app.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4 offset-1 px-5 px-md-1 preview-area">
                        <div class="mobile position-relative" style="z-index: 55">

                            <div class="desk-view">
                                <img alt="PeopleHello" class="dance"
                                     src="https://tailorie-common-static.s3.us-east-2.amazonaws.com/images/emails/welcome-email/PeopleHello%402x.png"
                                     style="position: absolute;left: -320px;bottom: -65px;z-index: 12;">
                                <img [ngClass]="{'toggleIndex': thumbnailData}" alt="iPhone"
                                     src="../../assets/images/iphone-white.svg"
                                     style="position: absolute; margin-left: -6px; z-index: 15">
                                <img *ngIf="thumbnailData" [src]="thumbnailData" alt="thumbnail image"
                                     style="position: absolute;left: 70px;top: 60%;z-index: 99;height: auto;width: 213px;transform: translateY(-50%);border-radius: 10px;"/>

                                <!--                                <img [ngClass]="{'toggleIndex': thumbnailData}" alt="iphone-frame"-->
                                <!--                                     src="../../assets/images/iphone-white.svg"-->
                                <!--                                     style="position: absolute; margin-left: -6px; z-index: 15">-->
                            </div>
                            <div (click)="selectVideo.click()" class="video-preview">
                                <img *ngIf="thumbnailData" [src]="thumbnailData" alt="thumbnail image"
                                     style="filter: blur(8px); webkit-filter: blur(8px); width: 100%;height: 100%;object-fit: cover;object-position: center; z-index: 30"/>
                                <div *ngIf="!thumbnailData" class="center">
                                    <img alt="cloud" src="assets/images/icons/cloudIcon.png"
                                         style="cursor: pointer; width: 90px;">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row foot-nav fixed-bottom">
                <div class="col-12 d-flex justify-content-between">
                    <button (click)="tabToggle(9)" class="btn-alter" type="button">Go Back</button>
                    <div class="d-flex flex-nowrap align-items-center">
                        <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">
                                  <img alt="error" src="assets/images/ErrorAlert.png"> {{alertMessage}}
                             </span>
                        <span *ngIf="showAlertMessage" class="alertMessage">
                                <img alt="success" src="assets/images/checkMark.png"> {{alertMessage}}
                             </span> &nbsp;&nbsp;&nbsp;
                        <button class="btn btn-primary" type="submit">Proceed
                            to
                            your dashboard
                        </button>
                    </div>
                </div>
            </div>
            <!--            <div class="row foot-nav fixed-bottom">-->
            <!--                <div class="col-12 d-flex justify-content-between">-->
            <!--                    <button (click)="tabToggle(9)" class="btn-alter" type="button">Go Back</button>-->
            <!--                    <button class="btn btn-primary" data-toggle="modal" href="#ignismyModal" type="submit">Proceed to-->
            <!--                        your dashboard-->
            <!--                    </button>-->
            <!--                </div>-->
            <!--            </div>-->
        </form>
    </div>
</main>
