import {AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../shared/services/user/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {debounceTime} from 'rxjs/operators';

import {ToastrService} from 'ngx-toastr';
import {Brand, BrandProductCategory, BrandSocialCause, BrandValue, BrandVibe} from '../shared/services/brand/brand';
import {AuthenticatedUser} from '../shared/services/auth/authenticateduser';
import {ProductCategory} from '../shared/services/product_category/product-category';
import {Vibe} from '../shared/services/vibe/vibe';
import {Value} from '../shared/services/value/value';
import {SocialCause} from '../shared/services/social_cause/social_cause';
import {AuthService} from '../shared/services/auth/auth.service';
import {ProductCategoryService} from '../shared/services/product_category/product-category.service';
import {VibeService} from '../shared/services/vibe/vibe.service';
import {ValueService} from '../shared/services/value/value.service';
import {SocialCauseService} from '../shared/services/social_cause/social-cause.service';
import {BrandService} from '../shared/services/brand/brand.service';
import {Options} from 'ng5-slider';

import {NavEditMenuModel} from '../shared/services/Brand Profile/brand-profile';
import Timer = NodeJS.Timer;

@Component({
    selector: 'app-edit-persona',
    templateUrl: './edit-brand-profile.component.html',
    styleUrls: ['./edit-brand-profile.component.scss'],
    animations: [
        trigger('slideInOut', [
            state('in', style({opacity: 1, transform: 'translateY(0)'})),
            transition('void => *', [
                style({opacity: 0, transform: 'translateY(100%)'}),
                animate(400),
            ]),
            transition('* => void', [
                animate(400, style({opacity: 0, transform: 'translateY(100%)'})),
            ]),
        ]),
    ],
})
export class EditBrandProfileComponent implements OnInit, AfterViewInit {

    cdnUrl = environment.webStaticResources;
    public responsiveWidth: any;
    currentUser: AuthenticatedUser;
    website: string;
    isOtherSocialCauseShown = false; // hidden by default
    currentBrandInfo: Brand;
    allProductCategories: Array<ProductCategory>;
    allVibes: Array<Vibe>;
    allValues: Array<Value>;
    allSocialCauses: Array<SocialCause>;
    public total = 0;
    public totalBrandValuesPer = 0;
    // <-----------------------Product Category Chart & Sliders---------------------->
    valsArr = [];
    brandvalsArr = [];
    autoProductCategory = [];
    autoVibes = [];
    autoValues = [];
    public vibesTotalPercentage = 0;
    autoSetProductCategories = true;
    autoSetVibes = true;
    autoSetValues = true;
    productCategoriesSliderOptions: Array<Options>;
    chartOptions = {
        cutoutPercentage: 65,
        plugins: {
            datalabels: {
                display: false
            }
        },
        elements: {
            arc: {
                borderWidth: 0,
            },
        },
    };
    defaultChartOption = {
        cutoutPercentage: 65,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                display: false
            }
        },
        elements: {
            arc: {
                borderWidth: 0,
            },
        },
        animation: {
            duration: 10,
            animateRotate: false,
        }
    };
    brandInfoForm: FormGroup;
    brandInfoFormSubmitted: boolean;
    updateProfilePicFlag = false;
    showAlertMessage = false;
    showAlertErrorMassage = false;
    alertMessage = '';
    alertTimeout: Timer;
    alertErrorTimeout: Timer;
    public myText = '';
    public vibeTxt = '';
    public valueTxt = '';
    public scsTxt = '';
    public desTxt = '';

    listOfYears: number[] = [];
    selectedYear: number = new Date().getFullYear();

    // <===================range value ============================>
    value1 = 50;
    singleSliderRange: Options = {
        floor: 0,
        ceil: 100,
        showOuterSelectionBars: true,
    };
    value = 20;
    highValue = 60;
    ageRangeSliderRange: Options = {
        floor: 0,
        ceil: 100,
        showOuterSelectionBars: true,
    };
    placeHolder = [];
    result: any[];
    showSaveBar = false;
    selectedTab = null;
    navMenuBindingList = [];
    selectedMenu: { id: string, label: string, fragment: string };
    isMobile = false;
    width: number = window.innerWidth;
    height: number = window.innerHeight;
    mobileWidth = 768;
    ageRequirement: number = null;
    ageRequirementSelected = -1;
    // Set max length input for sliders input
    pcMaxLength = 3;
    vbMaxLength = 3;
    valMaxLength = 3;
    pcLimitExceed = false;
    vibLimitExceed = false;
    valLimitExceed = false;
    private dateToday: Date = new Date();

    constructor(
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private authService: AuthService,
        private productCategoryService: ProductCategoryService,
        private vibeService: VibeService,
        private valueService: ValueService,
        private socialCauseService: SocialCauseService,
        private brandService: BrandService,
        private userService: UserService,
        public fb: FormBuilder,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        const urlReg = '(http(s)?:\\/\\/)?(www\\.)?[A-Za-z0-9]+([\\-\\.]{1}[A-Za-z0-9]+)*\\.[A-Za-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$';
        this.authService.currentUser.subscribe((x) => (this.currentUser = x));
        this.brandInfoForm = this.fb.group(
            {
                business_name: new FormControl('', {
                    validators: [
                        Validators.required,
                        Validators.minLength(3),
                        this.uniqueBrandName.bind(this),
                    ],
                    updateOn: 'blur',
                }),
                username: new FormControl('', {
                    validators: [Validators.required, Validators.minLength(3), this.uniqueUsername.bind(this)]
                }),
                title: [''],
                description: ['', Validators.required],
                phone: [''],
                sales_channel: ['', Validators.required],
                website: ['', [Validators.required, Validators.pattern(urlReg)]],
                headquarterLocation: ['', Validators.required],
                year_founded: ['', Validators.required],
                numberOfLocations: ['', Validators.required],
                amountBusinessRange: ['' || 50, Validators.required],
            },
            {
                // validator: MustMatch('password', 'confirm_password'),
            }
        );
        this.responsiveWidth = window.innerWidth;
    }

    /**
     * convenience getter for easy access to form fields of Brand User Registration Form
     */

    get bif() {
        return this.brandInfoForm.controls;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.width = event.target.innerWidth;
        this.height = event.target.innerHeight;
        this.isMobile = this.width < this.mobileWidth;
        if (this.width < 568) {
            this.initBrandInfo();
        }

    }

    initBrandInfo() {
        this.productCategoryService.GetAllProductCategories().subscribe(
            (productCategories) => {


                this.allProductCategories = productCategories.results;
                this.allProductCategories.forEach((productCategory, index) => {
                    if (productCategory.is_predefined) {
                        if (!productCategory.percentage) {
                            productCategory.percentage = 0;
                        }
                    }
                    productCategory.selected = false;

                    this.currentBrandInfo.product_categories.forEach(
                        (brandProductCategory, index2) => {
                            if (brandProductCategory.id === productCategory.id) {
                                productCategory.selected = true;
                                productCategory.min_price = brandProductCategory.min_price;
                                productCategory.max_price = brandProductCategory.max_price;
                                productCategory.avg_price = brandProductCategory.avg_price;
                                productCategory.tags = brandProductCategory.tags;
                                productCategory.percentage = brandProductCategory.percentage;
                                if (this.isMobile) {
                                    productCategory.tags_placeholders = this.first((productCategory.tags_placeholders), 3);
                                }
                            }
                        }
                    );
                });

                this.vibeService.GetAllVibes().subscribe((vibes) => {
                    this.allVibes = vibes.results;
                    this.allVibes.forEach((vibe, index) => {
                        vibe.selected = false;
                        if (!vibe.percentage) {
                            vibe.percentage = 0;
                        }
                        this.currentBrandInfo.vibes.forEach((brandVibe, index2) => {
                            if (brandVibe.id === vibe.id) {
                                vibe.selected = true;
                                vibe.percentage = brandVibe.percentage;
                            }
                        });
                    });
                    this.valueService.GetAllValues().subscribe((values) => {
                        this.allValues = values.results;
                        this.allValues.forEach((value, index) => {
                            value.selected = false;
                            if (value.is_predefined) {
                                if (!value.percentage) {
                                    value.percentage = 0;
                                }
                            }
                            this.currentBrandInfo.values.forEach((brandValue, index2) => {
                                if (brandValue.id === value.id) {
                                    value.selected = true;
                                    value.percentage = brandValue.percentage;
                                }
                            });
                        });
                        this.socialCauseService
                            .GetAllSocialCauses()
                            .subscribe((socialCauses) => {
                                this.allSocialCauses = socialCauses.results;
                                this.allSocialCauses.forEach((socialCause, index) => {
                                    socialCause.selected = false;
                                    this.currentBrandInfo.social_causes.forEach(
                                        (brandSocialCause, index2) => {
                                            if (brandSocialCause.id === socialCause.id) {
                                                socialCause.selected = true;
                                            }
                                        }
                                    );
                                });
                                if(this.currentBrandInfo.description) {
                                    this.currentBrandInfo.description = this.currentBrandInfo.description.trim();
                                }
                            });
                    });
                });

                // Setting Brand Info form values
                this.setFormValues();

            },
            (err) => {
            }
        );
    }

    setFormValues() {
        this.brandInfoForm.setValue({
            description: this.currentBrandInfo.description,
            business_name: this.currentBrandInfo.business_name,
            title: this.currentUser.user.branduserprofile.title,
            phone: this.currentUser.user.phone,
            sales_channel: this.currentBrandInfo.sales_channel,
            website: this.currentBrandInfo.website,
            headquarterLocation: this.currentBrandInfo.headquarter_location,
            year_founded: this.currentBrandInfo.year_founded,
            numberOfLocations: this.currentBrandInfo.number_of_locations,
            amountBusinessRange: this.currentBrandInfo.business_percent_online,
            username: this.currentBrandInfo.brand_username
        });
        this.brandInfoForm.controls.business_name.setErrors(null);
    }

    ngOnInit() {
        this.isMobile = this.width < this.mobileWidth;
        if (this.route.snapshot.fragment) {
            this.selectedTab = this.route.snapshot.fragment;
            if (this.route.snapshot.fragment.includes('bd')) {
                this.selectedTab = 'bd';
            } else if (this.route.snapshot.fragment.includes('pc')) {
                this.selectedTab = 'pc';
            } else if (this.route.snapshot.fragment.includes('vb')) {
                this.selectedTab = 'vb';
            } else if (this.route.snapshot.fragment.includes('vl')) {
                this.selectedTab = 'vl';
            } else if (this.route.snapshot.fragment.includes('sc')) {
                this.selectedTab = 'sc';
            }
        } else {
            this.selectedTab = 'bd';
        }
        const currentBrand = this.authService.currentUserValue.user.branduserprofile.brand;
        this.currentBrandInfo = new Brand(
            currentBrand.id,
            currentBrand.business_name,
            currentBrand.description,
            currentBrand.tags,
            currentBrand.brandsignupchecklist,
            currentBrand.brandcustomerprofile,
            currentBrand.product_categories,
            currentBrand.vibes,
            currentBrand.values,
            currentBrand.social_causes,
            currentBrand.sales_channel,
            currentBrand.website,
            currentBrand.headquarter_location,
            currentBrand.tax_id,
            currentBrand.years_in_business,
            currentBrand.year_founded,
            currentBrand.number_of_locations,
            currentBrand.business_percent_online,
            currentBrand.business_percent_instore,
            currentBrand.brand_username,
            currentBrand.logo_url,
            currentBrand.minimum_age_required
        );
        this.currentBrandInfo.initBrandInfo();
        if (this.currentBrandInfo.minimum_age_required && this.currentBrandInfo.minimum_age_required > 1) {
            this.ageRequirementSelected = 2;
            this.ageRequirement = +this.currentBrandInfo.minimum_age_required;
        } else {
            this.ageRequirementSelected = 1;
            this.ageRequirement = null;
        }
        this.initBrandInfo();
        this.navMenuBindingList = new NavEditMenuModel().getNavEditMenu();
        const selectedData = this.navMenuBindingList[this.navMenuBindingList.map((item) => {
            return item.fragment;
        }).indexOf(this.selectedTab)];
        this.selectedMenu = selectedData ? selectedData.label : null;

        const currYear = new Date().getFullYear();
        for (let i = 1800; i <= currYear; i++) {
            this.listOfYears.push(i);
        }
        this.listOfYears.reverse();
    }

    ngAfterViewInit() {
        this.changeDetector();
    }

    loadMenu(selectedItem) {
        this.router.navigate([], {fragment: selectedItem.fragment});
        this.showTabContentDropdown(selectedItem.id, selectedItem.fragment);
    }

    changeDetector() {
        const allInputs = document.querySelectorAll('input');
        const allTextareas = document.querySelectorAll('textarea');

        allInputs.forEach((value, key) => {
            value.addEventListener('keyup', () => {
                this.showSaveBar = true;
            });
        });

        allTextareas.forEach((value, key) => {
            value.addEventListener('keyup', () => {
                this.showSaveBar = true;
            });
        });
    }

    // <=====================================Description & Basic info============================================>

    first(array, n) {
        if (array == null) {
            return void 0;
        }
        if (n == null) {
            return array[0];
        }
        if (n < 0) {
            return [];
        }
        return array.slice(0, n);
    }

    /**
     * Validator for Brand User Registration Form to check if the Business/Brand Name is available
     */
    uniqueBrandName(control: AbstractControl) {
        const businessName = control.value;

        if (businessName.length > 2 && businessName !== this.currentBrandInfo.business_name) {
            this.brandService.GetBrandByName(businessName).subscribe(
                (res) => {
                    control.setErrors({notUniqueBrandName: true});
                },
                (err) => {
                    if (err.status === 404) {
                        return null;
                    }
                    control.setErrors({notUniqueBrandName: true});
                }
            );
        }
        return null;
    }

    // Unique Username Validator
    uniqueUsername(control: AbstractControl) {
        const username = control.value;

        const usernameMatched = username === this.currentUser.user.branduserprofile.brand.brand_username;

        if (usernameMatched) {
            return null;
        }

        if (username.length > 2) {
            this.brandService.checkBrandUsernameAvailability(username).pipe(debounceTime(800))
                .subscribe(
                    (res) => {
                        if (res.message === 'available'){
                            return null;
                        }
                        control.setErrors({notUniqueUsername: true});
                    },
                    (err) => {
                        control.setErrors({notUniqueUsername: true});
                    }
                );
        }
        return null;
    }

    // Set Suggested Username
    setUsername() {
        this.brandService
            .GetSuggestedUsername(this.brandInfoForm.get('business_name').value)
            .subscribe((username) => {
                this.brandInfoForm.patchValue({username: username.message});
            });
    }

    updateBasicInfo() {
        const description = document.getElementById(`branddescriptioncomment`) as HTMLInputElement;
        if (description.value.length < 3) {
            this.showAlertError('Oops! It looks like information is missing.');
        } else {
            const brandDetails = {
                description: description.value,
                website: this.currentBrandInfo.website,
                tags: this.currentBrandInfo.tags,
                business_name: this.brandInfoForm.value.business_name,
                brand_username: this.brandInfoForm.value.username,
                sales_channel: this.brandInfoForm.value.sales_channel,
                headquarter_location: this.brandInfoForm.value.headquarterLocation,
                years_in_business: this.brandInfoForm.value.yearsInBusiness,
                year_founded: this.brandInfoForm.value.year_founded,
                number_of_locations: this.brandInfoForm.value.numberOfLocations,
                business_percent_online: this.brandInfoForm.value.amountBusinessRange,
                business_percent_instore:
                    100 - this.brandInfoForm.value.amountBusinessRange,
            };


            if (this.updateProfilePicFlag) {
                const picELem = document.getElementById('profilePic') as HTMLInputElement;
                const formData = new FormData();
                formData.append('logo', picELem.files[0]);
                this.brandService.UpdateBrandLogo(this.currentBrandInfo.id, formData)
                    .subscribe(resp => {

                        this.currentBrandInfo.logo_url = resp.logo;
                        this.authService.updateCurrentUserBrand(this.currentBrandInfo);


                        this.brandService
                            .UpdateBrandById(this.currentBrandInfo.id, brandDetails)
                            .subscribe(
                                (brand) => {
                                    // this.toastr.success('Brand Info Updated!');
                                    this.authService.updateCurrentUserBrand(brand);
                                    // this.initBrandInfo();
                                    this.showAlert('Brand Info Updated!');
                                    // setTimeout(() => {
                                    //     console.log("issue id  sdasdasd")
                                    //     window.location.reload();
                                    // }, 4000);
                                    return false;
                                },
                                (err) => {
                                    this.showAlertError(
                                        'Something went wrong while updating the brand info!'
                                    );
                                    // this.toastr.error('Something went wrong while updating the brand info!');
                                }
                            );
                        // this.authService.updateCurrentUserBrand(brand);


                        this.showSaveBar = false;
                    }, error => {
                        this.showAlertError(
                            'Something went wrong while updating the brand info!'
                        );
                    });
            } else {
                this.brandService
                    .UpdateBrandById(this.currentBrandInfo.id, brandDetails)
                    .subscribe(
                        (brand) => {
                            // this.toastr.success('Brand Info Updated!');
                            this.authService.updateCurrentUserBrand(brand);
                            // this.initBrandInfo();
                            this.showAlert('Brand Info Updated!');
                            // setTimeout(() => {
                            //     console.log("issue is here 4")
                            //     window.location.reload();
                            // }, 5000);
                            return false;
                        },
                        (err) => {
                            this.showAlertError(
                                'Something went wrong while updating the brand info!'
                            );
                            // this.toastr.error('Something went wrong while updating the brand info!');
                        }
                    );
            }
        }
    }

    addCustomDescriptionTags() {
        const customBrandTags = document.getElementById(
            `descriptionTags`
        ) as HTMLInputElement;
        let customBrandTagsArray: Array<string> = customBrandTags.value.split(',');
        customBrandTagsArray.forEach((tag, index) => {
            customBrandTagsArray[index] = tag.trim();
        });
        customBrandTagsArray = customBrandTagsArray.filter((e) => e);
        if (customBrandTags.value === null || customBrandTags.value === '') {
            this.showAlertError('Customer Description Tags Empty!');
        } else {
            // tslint:disable-next-line:only-arrow-functions
            const uniqueTags = customBrandTagsArray.filter(function(
                elem,
                index,
                self
            ) {
                return index === self.indexOf(elem);
            });

            if (this.currentBrandInfo.tags != null) {
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < this.currentBrandInfo.tags.length; i++) {
                    uniqueTags.push(this.currentBrandInfo.tags[i]);
                }
            }
            this.currentBrandInfo.tags = uniqueTags.filter(
                (elem, index, self) => index === self.indexOf(elem)
            );
            customBrandTags.value = '';
        }
    }

    removeBrandDescriptionTags(tagWord) {
        const index: number = this.currentBrandInfo.tags.indexOf(tagWord);
        if (index !== -1) {
            this.currentBrandInfo.tags.splice(index, 1);
        }
    }

    // <=======================================Product Category==================================================>
    /**
     * Handler for adding custom product categories
     */
    updateBrandProductCategories() {
        return new Promise((resolve, reject) => {
            const updatedProductCategories = [];

            this.currentBrandInfo.product_categories.forEach(
                (productCategory, index2) => {
                    const minPrice = document.getElementById(
                        `min-price-${productCategory.id}`
                    ) as HTMLInputElement;
                    const maxPrice = document.getElementById(
                        `max-price-${productCategory.id}`
                    ) as HTMLInputElement;
                    const avgPrice = document.getElementById(
                        `avg-price-${productCategory.id}`
                    ) as HTMLInputElement;

                    const upc = new BrandProductCategory(
                        productCategory.id,
                        productCategory.min_price,
                        productCategory.max_price,
                        productCategory.avg_price,
                        productCategory.tags,
                        productCategory.percentage
                    );

                    if (minPrice !== null && maxPrice !== null) {
                        // tslint:disable-next-line:radix
                        upc.min_price = parseInt(minPrice.value);
                        // tslint:disable-next-line:radix
                        upc.max_price = parseInt(maxPrice.value);
                        // tslint:disable-next-line:radix
                        upc.avg_price = parseInt(avgPrice.value);
                    }

                    updatedProductCategories.push(upc._obj());
                }
            );

            const brandDetails = {
                brandsignupchecklist: {
                    selected_product_categories: true,
                },
                minimum_age_required: this.ageRequirementSelected === 2 ? this.ageRequirement : null,
                product_categories: updatedProductCategories,
            };

            this.brandService
                .UpdateBrandById(this.currentBrandInfo.id, brandDetails)
                .subscribe(
                    (brand) => {
                        this.authService.updateCurrentUserBrand(brand);
                        resolve(true);
                    },
                    (err) => {
                        this.showAlertError(
                            'Something went wrong while updating the brand info!'
                        );
                        reject(false);
                    }
                );
        });
    }

    onKeyUp(event) {
        if (event.key === ',') {
            const customCategoriesArray: Array<string> = event.target.value.split(
                ','
            );
            if (event.target.value.value === null || event.target.value === '') {
                this.showAlertError('Tags Empty!');
            } else {
                customCategoriesArray.forEach((customCat, index) => {
                    let exists = false;
                    customCat = customCat.trim();
                    this.allProductCategories.forEach((productCategory, index2) => {
                        if (customCat !== '') {
                            if (productCategory.title === customCat) {
                                exists = true;
                                const bpc = new BrandProductCategory(productCategory.id);
                                this.currentBrandInfo.product_categories.push(bpc);
                            }
                        }
                    });

                    if (!exists && customCat !== '') {
                        const data = {
                            title: customCat,
                        };
                        this.productCategoryService.CreateProductCategory(data).subscribe(
                            (productCategory) => {
                                const bpc = new BrandProductCategory(productCategory.id);
                                this.currentBrandInfo.product_categories.push(bpc);

                                this.updateBrandProductCategories();
                                this.initBrandInfo();
                            },
                            (error) => {
                            }
                        );
                    } else {
                        this.updateBrandProductCategories();
                    }
                });
                event.target.value = '';
            }
        }
    }

    addCustomProductCategory() {
        this.myText = '';
        const customCategories = document.getElementById(`ProductCategories`) as HTMLInputElement;
        const customCategoriesArray: Array<string> = customCategories.value.split(
            ','
        );
        if (customCategories.value === null || customCategories.value === '') {
            this.showAlertError('Tags Empty!');
        } else {
            customCategoriesArray.forEach((customCat, index) => {
                let exists = false;
                customCat = customCat.trim();
                this.allProductCategories.forEach((productCategory, index2) => {
                    if (customCat !== '') {
                        if (productCategory.title === customCat) {
                            exists = true;
                            const bpc = new BrandProductCategory(productCategory.id);
                            this.currentBrandInfo.product_categories.push(bpc);
                        }
                    }
                });

                if (!exists && customCat !== '') {
                    const data = {
                        title: customCat,
                    };
                    this.productCategoryService.CreateProductCategory(data).subscribe(
                        (productCategory) => {
                            const bpc = new BrandProductCategory(productCategory.id);
                            this.currentBrandInfo.product_categories.push(bpc);

                            this.updateBrandProductCategories();
                            this.initBrandInfo();
                        },
                        (error) => {
                        }
                    );
                } else {
                    this.updateBrandProductCategories();
                }
            });
            customCategories.value = '';
        }
    }

    removeCustomProductCategory(tagid) {
        const index: number = this.currentBrandInfo.product_categories.indexOf(
            this.currentBrandInfo.product_categories.find((x) => x.id === tagid)
        );
        if (index !== -1) {
            this.currentBrandInfo.product_categories.splice(index, 1);
        }
        this.updateBrandProductCategories().then(() => {
            this.initBrandInfo();
        });
    }

    addProductCategoryDetailsTags(pid: number, value?: any) {
        const customTagCategories = document.getElementById(`productDetail-${pid}`) as HTMLInputElement;
        const minPrice = document.getElementById(
            `min-price-${pid}`
        ) as HTMLInputElement;
        const maxPrice = document.getElementById(
            `max-price-${pid}`
        ) as HTMLInputElement;
        const avgPrice = document.getElementById(
            `avg-price-${pid}`
        ) as HTMLInputElement;
        let customTagCategoriesArray: Array<string> = customTagCategories.value.split(
            ','
        );
        customTagCategoriesArray.forEach((tag, index) => {
            customTagCategoriesArray[index] = tag.trim();
        });
        customTagCategoriesArray = customTagCategoriesArray.filter((e) => e);
        if (customTagCategories.value === null || customTagCategories.value === '') {
            this.showAlertError('Tags Empty!');
        } else {
            // tslint:disable-next-line:only-arrow-functions
            const uniqueTags = customTagCategoriesArray.filter(function(
                elem,
                index,
                self
            ) {
                return index === self.indexOf(elem);
            });

            this.currentBrandInfo.product_categories.forEach(
                (productCategory, index2) => {
                    if (productCategory.id === pid) {
                        if (minPrice !== null && maxPrice !== null && avgPrice !== null) {
                            // tslint:disable-next-line:radix
                            productCategory.min_price = parseInt(minPrice.value);
                            // tslint:disable-next-line:radix
                            productCategory.max_price = parseInt(maxPrice.value);
                            // tslint:disable-next-line:radix
                            productCategory.avg_price = parseInt(avgPrice.value);
                            if (productCategory.tags != null) {
                                // tslint:disable-next-line:prefer-for-of
                                for (let i = 0; i < productCategory.tags.length; i++) {
                                    uniqueTags.push(productCategory.tags[i]);
                                }
                            }
                            productCategory.tags = uniqueTags.filter(
                                (elem, index, self) => index === self.indexOf(elem)
                            );
                        } else {
                            this.showAlertError(
                                'Price range for product category is required'
                            );
                        }
                    }
                }
            );
            this.updateBrandProductCategories().then(() => {
                this.initBrandInfo();
            });
        }
    }

    ProductDetailsTags(pid: number, value?: any) {
        const customTagCategories = document.getElementById(`productTag-${pid}`) as HTMLInputElement;
        const minPrice = document.getElementById(
            `min-price-${pid}`
        ) as HTMLInputElement;
        const maxPrice = document.getElementById(
            `max-price-${pid}`
        ) as HTMLInputElement;
        const avgPrice = document.getElementById(
            `avg-price-${pid}`
        ) as HTMLInputElement;
        let customTagCategoriesArray: Array<string> = customTagCategories.value.split(
            ','
        );
        customTagCategoriesArray.forEach((tag, index) => {
            customTagCategoriesArray[index] = tag.trim();
        });
        customTagCategoriesArray = customTagCategoriesArray.filter((e) => e);
        if (customTagCategories.value === '') {
            this.showAlertError('Tags Empty!');
        } else {
            // tslint:disable-next-line:only-arrow-functions
            const uniqueTags = customTagCategoriesArray.filter(function(
                elem,
                index,
                self
            ) {
                return index === self.indexOf(elem);
            });

            this.currentBrandInfo.product_categories.forEach(
                (productCategory, index2) => {
                    if (productCategory.id === pid) {
                        if (minPrice !== null && maxPrice !== null && avgPrice !== null) {
                            // tslint:disable-next-line:radix
                            productCategory.min_price = parseInt(minPrice.value);
                            // tslint:disable-next-line:radix
                            productCategory.max_price = parseInt(maxPrice.value);
                            // tslint:disable-next-line:radix
                            productCategory.avg_price = parseInt(avgPrice.value);
                            if (productCategory.tags != null) {
                                // tslint:disable-next-line:prefer-for-of
                                for (let i = 0; i < productCategory.tags.length; i++) {
                                    uniqueTags.push(productCategory.tags[i]);
                                }
                            }
                            productCategory.tags = uniqueTags.filter(
                                (elem, index, self) => index === self.indexOf(elem)
                            );
                        } else {
                            this.showAlertError(
                                'Price range for product category is required'
                            );
                        }
                    }
                }
            );
            this.updateBrandProductCategories().then(() => {
                this.initBrandInfo();
            });
        }
    }

    removeProductCategoryTags(pid, tagWord) {
        const productCategory = this.currentBrandInfo.product_categories.find(
            (x) => x.id === pid
        );
        const index: number = productCategory.tags.indexOf(tagWord);
        if (index !== -1) {
            productCategory.tags.splice(index, 1);
        }

        this.currentBrandInfo.product_categories.find((x) => x.id === pid).tags =
            productCategory.tags;
        this.updateBrandProductCategories();
    }

    /**
     * Check if product category is already selected or not
     */
    productCategorySelected(productCategory: ProductCategory) {
        for (const selectedCategory of this.currentBrandInfo.product_categories) {
            if (selectedCategory.id === productCategory.id) {
                return true;
            }
        }
        return false;
    }

    /**
     * Routine to remove a selected category
     */
    removeSelectedCategory(productCategory: ProductCategory) {
        this.currentBrandInfo.product_categories = this.currentBrandInfo.product_categories.filter(
            (obj) => obj.id !== productCategory.id
        );
    }

    /**
     * Handler for product category toggle
     */
    toggleBrandCategory(productCategory: ProductCategory) {
        this.showSaveBar = true;
        const alreadySelected: boolean = this.productCategorySelected(
            productCategory
        );

        const index = this.autoProductCategory.indexOf(productCategory);
        if (index > -1) {
            this.autoProductCategory.splice(index, 1);
        } else {
            this.autoProductCategory.push(productCategory);
        }

        if (alreadySelected) {
            this.removeSelectedCategory(productCategory);

            if (
                this.currentBrandInfo.product_categories === undefined ||
                this.currentBrandInfo.product_categories.length === 0
            ) {
                this.currentBrandInfo.product_categories = [];
            }
        } else {
            const bpc = new BrandProductCategory(productCategory.id);
            this.currentBrandInfo.product_categories.push(bpc);
        }

        if (+this.currentBrandInfo.getProductCategoryCount(this.allProductCategories) < 1) {
            this.autoSetProductCategories = true;
        }

        if (this.autoSetProductCategories) {
            this.setAutoPercentage();
        }

        this.updateBrandProductCategories()
            .then((r) => {
                this.initBrandInfo();
            })
            .catch((err) => {
            });
    }

    setAutoPercentage() {
        const selected = this.autoProductCategory;
        // const selNumber = this.brandInfo.product_categories.length;
        const selNumber = this.currentBrandInfo.getProductCategoryCount(
            this.allProductCategories,
            true
        );
        let currentSet = [];
        currentSet = this.getSliderValuesSet(selNumber);
        this.allProductCategories.forEach((productCategory, index2) => {
            if (productCategory.is_predefined) {
                this.currentBrandInfo.product_categories.forEach((element, index) => {
                    if (productCategory.id === element.id) {
                        // tslint:disable-next-line:radix
                        element.percentage = parseInt(currentSet.shift());
                    }
                });
            }
        });
    }

    /**
     * On click handler for Product Categories "next" click
     */
    submitBrandProductCategories() {

        if (this.ageRequirementSelected < 1) {
            if (this.responsiveWidth < 768) {
                this.toastr.error('Minimum age requirement field is mandatory.');
            } else {
                this.showAlertError('Minimum age requirement field is mandatory.');
            }
            return;
        }
        if (this.ageRequirementSelected === 2 && (!this.ageRequirement || this.ageRequirement < 1)) {
            if (this.responsiveWidth < 768) {
                this.toastr.error('Minimum age requirement field is mandatory.');
            } else {
                this.showAlertError('Minimum age requirement field is mandatory.');
            }
            return;
        }

        if (this.currentBrandInfo.getProductCategoryCount(this.allProductCategories) > 0) {
            if (this.total === 100) {
                this.updateBrandProductCategories()
                    .then((resp) => {
                        this.showAlert('Product Categories Updated!');
                        // this.ngOnInit();
                        // this.initBrandInfo();
                        // setTimeout(() => {
                        //     window.location.reload();
                        // }, 5000);
                        return false;
                    })
                    .catch((err) => {
                        this.showAlertError('Something went wrong!');
                        return false;
                    });
            } else if (this.total > 100) {
                this.showAlertError(
                    'Oops! You’ve gone too far. Allocate 100% before continuing.'
                );
            } else {
                this.showAlertError('Whoops! Allocate 100% To Proceed!');
            }
        } else {
            this.showAlertError(
                'Whoops! You forgot to select at least one product category.'
            );
        }
    }

    /**
     * Handler for Product Category Details Submission, including the update to DB
     */
    updateProductCategoryDetails() {
        return new Promise((resolve, reject) => {
            const updatedProductCategories = [];
            this.currentBrandInfo.product_categories.forEach(
                (productCategory, index) => {
                    const minPrice = document.getElementById(
                        `min-price-${productCategory.id}`
                    ) as HTMLInputElement;
                    const maxPrice = document.getElementById(
                        `max-price-${productCategory.id}`
                    ) as HTMLInputElement;
                    const avgPrice = document.getElementById(
                        `avg-price-${productCategory.id}`
                    ) as HTMLInputElement;
                    if (minPrice !== null && maxPrice !== null) {
                        // tslint:disable-next-line:radix
                        const upc = new BrandProductCategory(
                            productCategory.id,

                            // tslint:disable-next-line:radix
                            parseInt(minPrice.value),
                            // tslint:disable-next-line:radix
                            parseInt(maxPrice.value),
                            // tslint:disable-next-line:radix
                            parseInt(avgPrice.value),
                            productCategory.tags
                        );
                        updatedProductCategories.push(upc._obj());
                    }
                }
            );

            const brandDetails = {
                product_categories: updatedProductCategories,
            };

            this.brandService
                .UpdateBrandById(this.currentBrandInfo.id, brandDetails)
                .subscribe(
                    (brand) => {
                        resolve(brand);
                        // this.toastr.success('Updated Categories Information');
                    },
                    (err) => {
                        this.showAlertError(
                            'Something went wrong while updating the brand info!'
                        );
                        reject(false);
                    }
                );
        });
    }

    // <=================================================Product Category Chart & Sliders================================================>

    sumOfProductCategoriesPercentages() {
        this.total = 0;
        this.currentBrandInfo.product_categories.forEach(
            (productCategory, index) => {
                // @ts-ignore
                // tslint:disable-next-line:radix
                this.total += parseFloat(productCategory.percentage);
            }
        );

        return this.total;
    }

    getProductCategoryChartData() {
        // tslint:disable-next-line:prefer-for-of
        if (this.sumOfProductCategoriesPercentages() === 0) {
            return ['100'];
        }
        this.valsArr = [];
        if (
            Array.isArray(this.allProductCategories) &&
            this.allProductCategories.length
        ) {
            this.allProductCategories.forEach((productCategory, index) => {
                this.valsArr.push(productCategory.percentage);
            });
        }
        return this.valsArr;
    }

    getProductCategoryChartsColors() {
        if (this.sumOfProductCategoriesPercentages() === 0) {
            return [{backgroundColor: ['#f0f0f0']}];
        }
        const colors = [];
        if (
            Array.isArray(this.allProductCategories) &&
            this.allProductCategories.length
        ) {
            this.allProductCategories.forEach((productCategory, index) => {
                if (productCategory.is_predefined) {
                    colors.push(productCategory.color);
                }
            });
        }
        return [{backgroundColor: colors}];
    }

    getProductCategoryChartsLabels() {
        if (this.sumOfProductCategoriesPercentages() === 0) {
            return ['Unallocated'];
        }
        const arr = [];
        if (
            Array.isArray(this.allProductCategories) &&
            this.allProductCategories.length
        ) {
            this.allProductCategories.forEach((productCategory, index) => {
                if (productCategory.is_predefined) {
                    arr.push(productCategory.title);
                }
            });
        }
        return arr;
    }

    onProductCategorySliderChange($event, productCategory: ProductCategory) {
        let val = $event.target.value;
        const alreadySelected: boolean = this.productCategorySelected(productCategory);

        // this.autoSetProductCategories = false;
        if (val > 100) {
            this.showAlertError(
                'Oops! You are not allowed to Enter value greater then 100'
            );
        } else if (val < 1) {

            const index = this.autoProductCategory.indexOf(productCategory);
            if (index > -1) {
                this.autoProductCategory.splice(index, 1);
            } else {
                this.autoProductCategory.push(productCategory);
            }

            if (alreadySelected) {
                this.removeSelectedCategory(productCategory);


                if (this.currentBrandInfo.product_categories === undefined || this.currentBrandInfo.product_categories.length === 0) {
                    this.currentBrandInfo.product_categories = [];
                }

                this.updateBrandProductCategories().then(() => {
                    this.setAutoPercentage();
                    this.initBrandInfo();
                });
            }
        } else {

            if (!alreadySelected) {
                const bpc = new BrandProductCategory(productCategory.id);
                this.currentBrandInfo.product_categories.push(bpc);
                this.updateBrandProductCategories().then(() => {
                    this.initBrandInfo();
                });
                this.changeDetectorRef.markForCheck();
            }

            // tslint:disable-next-line:radix
            productCategory.percentage = parseInt(val);
            this.currentBrandInfo.product_categories.forEach((pc, index) => {
                if (pc.id === productCategory.id) {
                    // tslint:disable-next-line:radix
                    pc.percentage = parseInt(val);
                }
            });

            const currTotal = this.sumOfProductCategoriesPercentages();

            if (currTotal > 100) {
                const diff = currTotal - 100;
                val = val - diff;
                this.pcLimitExceed = true;
                // tslint:disable-next-line:radix
                productCategory.percentage = parseInt(val);
                this.currentBrandInfo.product_categories.forEach((pc, index) => {
                    if (pc.id === productCategory.id) {
                        // tslint:disable-next-line:radix
                        pc.percentage = parseInt(val);
                    }
                });
                // document.getElementById()
                $event.target.value = `${val}`;
                this.changeDetectorRef.markForCheck();
            } else {
                this.showSaveBar = true;
                this.pcLimitExceed = false;
            }

        }
    }

    // Set value of selected PRODUCT CATEGORY by entering value in input field
    maxPcValueCheck(event: any, productCategory: ProductCategory) {

        // Check if number key is pressed
        const isNumber = /^[0-9]$/i.test(event.key) || event.key === 'Backspace';

        if (isNumber) {

            this.showSaveBar = true;

            if (+event.target.value > 100) {
                if (this.responsiveWidth > 768) {
                    this.showAlertError('Oops! You are not allowed to Enter value greater then 100');
                }
            }

            this.currentBrandInfo.product_categories.forEach((pc, index) => {
                if (pc.id === productCategory.id) {
                    pc.percentage = +event.target.value;
                    productCategory.percentage = +event.target.value;
                }
            });

        }

    }

    // <==================================================Vibes======================================================>
    updateVibe() {
        return new Promise((resolve, reject) => {
            const updatedVibes = [];

            this.currentBrandInfo.vibes.forEach((vibe, index) => {
                const uv = new BrandVibe(vibe.id, vibe.percentage);
                updatedVibes.push(uv._obj());
            });

            const brandDetails = {
                brandsignupchecklist: {
                    selected_brand_vibes: true,
                },
                vibes: updatedVibes,
            };

            this.brandService
                .UpdateBrandById(this.currentBrandInfo.id, brandDetails)
                .subscribe(
                    (brand) => {
                        this.authService.updateCurrentUserBrand(brand);
                        this.initBrandInfo();
                        resolve(true);
                    },
                    (err) => {
                        this.showAlertError(
                            'Something went wrong while updating the brand info!'
                        );
                        reject(false);
                    }
                );
        });
    }

    addCustomVibeTags() {
        this.vibeTxt = '';
        const customVibeTag = document.getElementById(
            `vibeTags`
        ) as HTMLInputElement;
        const customVibeTagsArray: Array<string> = customVibeTag.value.split(',');
        if (customVibeTag.value === null || customVibeTag.value === '') {
            this.showAlertError('Vibes Tags Empty!');
        } else {

            customVibeTagsArray.forEach((customTag, index) => {
                let exists = false;
                customTag = customTag.trim();
                this.allVibes.forEach((vibe, index2) => {
                    if (customTag !== '') {
                        if (vibe.title === customTag) {
                            exists = true;
                            const bv = new BrandVibe(vibe.id);
                            this.currentBrandInfo.vibes.push(bv);
                        }
                    }
                });

                if (!exists && customTag !== '') {
                    const data = {
                        title: customTag,
                    };
                    this.vibeService.CreateVibes(data).subscribe((vibe) => {
                        const bv = new BrandVibe(vibe.id);
                        this.currentBrandInfo.vibes.push(bv);
                        this.updateVibe();
                    });
                } else {
                    this.updateVibe();
                }
            });
            customVibeTag.value = '';
        }
    }

    vibeSelected(vibe: Vibe) {
        for (const selectedVibe of this.currentBrandInfo.vibes) {
            if (selectedVibe.id === vibe.id) {
                return true;
            }
        }
        return false;
    }

    removeSelectedVibe(vibe: Vibe) {
        this.currentBrandInfo.vibes = this.currentBrandInfo.vibes.filter(
            (obj) => obj.id !== vibe.id
        );
    }

    toggleBrandVibe(vibe: Vibe) {
        this.showSaveBar = true;
        const alreadySelected: boolean = this.vibeSelected(vibe);

        const index = this.autoVibes.indexOf(vibe);
        if (index > -1) {
            this.autoVibes.splice(index, 1);
        } else {
            this.autoVibes.push(vibe);
        }

        if (alreadySelected) {
            this.removeSelectedVibe(vibe);

            if (
                this.currentBrandInfo.vibes === undefined ||
                this.currentBrandInfo.vibes.length === 0
            ) {
                this.currentBrandInfo.vibes = [];
            }
        } else {
            const bv = new BrandVibe(vibe.id);
            this.currentBrandInfo.vibes.push(bv);
        }

        if (+this.currentBrandInfo.getVibesCount(this.allVibes) < 1) {
            this.autoSetVibes = true;
        }

        if (this.autoSetVibes) {
            this.setAutoVibePercentage();
        }
        this.updateVibe();
    }

    setAutoVibePercentage() {
        const selected = this.autoVibes;
        const selNumber = this.currentBrandInfo.getVibesCount(this.allVibes, true);
        let currentSet = [];
        currentSet = this.getSliderValuesSet(selNumber);
        this.allVibes.forEach((vibe, index2) => {
            if (vibe.is_predefined) {
                this.currentBrandInfo.vibes.forEach((element, index) => {
                    if (vibe.id === element.id) {
                        // tslint:disable-next-line:radix
                        element.percentage = parseInt(currentSet.shift());
                    }
                });
            }
        });
        // this.brandInfo.vibes.forEach((element, index) => {
        //     // tslint:disable-next-line:radix
        //     element.percentage = parseInt(currentSet[index]);
        // });
    }

    submitBrandVibes() {
        if (this.currentBrandInfo.getVibesCount(this.allVibes) > 0) {
            if (this.vibesTotalPercentage === 100) {
                this.updateVibe()
                    .then((resp) => {
                        this.showAlert('Vibes Updated!');
                        // setTimeout(() => {
                        //     window.location.reload();
                        // }, 5000);
                        // this.showSaveBar = false;
                        return false;
                    })
                    .catch((err) => {
                        this.showAlertError('Something went wrong!');
                        return false;
                    });
            } else if (this.vibesTotalPercentage > 100) {
                this.showAlertError(
                    'Whoops! You Have Gone Too far. You are allowed to allocate maximum of 100%'
                );
            } else {
                this.showAlertError('Whoops! Allocate 100% to proceed');
            }
        } else {
            this.showAlertError(
                'We know you’ve got a great vibe, please select at least one so others know too!'
            );
        }
    }

    removeVibesTags(tagid) {
        if (this.currentBrandInfo.vibes.length !== 1) {
            const index: number = this.currentBrandInfo.vibes.indexOf(
                this.currentBrandInfo.vibes.find((x) => x.id === tagid)
            );
            if (tagid !== -1) {
                this.currentBrandInfo.vibes.splice(index, 1);
            }
            this.updateVibe();
        } else {
            this.showAlertError('Minimum one vibe is required!');
        }
    }

    // <=================================================Vibes Chart & Sliders================================================>

    sumOfVibesPercentages() {
        this.vibesTotalPercentage = 0;
        this.currentBrandInfo.vibes.forEach((vibe, index) => {
            // @ts-ignore
            // tslint:disable-next-line:radix
            this.vibesTotalPercentage += parseFloat(vibe.percentage);
        });

        return this.vibesTotalPercentage;
    }

    getVibesChartData() {
        // tslint:disable-next-line:prefer-for-of
        if (this.sumOfVibesPercentages() === 0) {
            return ['100'];
        }
        this.valsArr = [];
        if (Array.isArray(this.allVibes) && this.allVibes.length) {
            this.allVibes.forEach((vibe, index) => {
                this.valsArr.push(vibe.percentage);
            });
        }
        return this.valsArr;
    }

    getVibesChartsColors() {
        if (this.sumOfVibesPercentages() === 0) {
            return [{backgroundColor: ['#f0f0f0']}];
        }
        const colors = [];
        if (Array.isArray(this.allVibes) && this.allVibes.length) {
            this.allVibes.forEach((vibe, index) => {
                if (vibe.is_predefined) {
                    colors.push(vibe.color);
                }
            });
        }
        return [{backgroundColor: colors}];
    }

    getVibesChartsLabels() {
        if (this.sumOfVibesPercentages() === 0) {
            return ['Unallocated'];
        }
        const arr = [];
        if (Array.isArray(this.allVibes) && this.allVibes.length) {
            this.allVibes.forEach((vibe, index) => {
                if (vibe.is_predefined) {
                    arr.push(vibe.title);
                }
            });
        }
        return arr;
    }

    onVibesSliderChange($event, vibe: Vibe) {
        let val2 = $event.target.value;
        const alreadySelected: boolean = this.vibeSelected(vibe);
        this.autoSetVibes = false;
        if (val2 > 100) {
            this.showAlertError(
                'Oops! You are not allowed to Enter value greater then 100'
            );
        } else if (val2 < 1) {

            const index = this.autoVibes.indexOf(vibe);
            if (index > -1) {
                this.autoVibes.splice(index, 1);
            } else {
                this.autoVibes.push(vibe);
            }

            if (alreadySelected) {
                this.removeSelectedVibe(vibe);

                if (this.currentBrandInfo.vibes === undefined || this.currentBrandInfo.vibes.length === 0) {
                    this.currentBrandInfo.vibes = [];
                }

                this.updateVibe().then(() => {
                    this.setAutoVibePercentage();
                    this.initBrandInfo();
                });

            }

        } else {

            if (!alreadySelected) {
                const bv = new BrandVibe(vibe.id);
                this.currentBrandInfo.vibes.push(bv);
                this.updateVibe().then(() => {
                    this.initBrandInfo();
                });
                this.changeDetectorRef.markForCheck();
            }

            // tslint:disable-next-line:radix
            vibe.percentage = parseInt(val2);
            this.currentBrandInfo.vibes.forEach((v, index) => {
                if (v.id === vibe.id) {
                    // tslint:disable-next-line:radix
                    v.percentage = parseInt(val2);
                }
            });

            const currTotal = this.sumOfVibesPercentages();

            if (currTotal > 100) {
                this.vibLimitExceed = true;
                const diff = currTotal - 100;
                val2 = val2 - diff;

                this.vibesTotalPercentage = currTotal;

                // tslint:disable-next-line:radix
                vibe.percentage = parseInt(val2);
                this.currentBrandInfo.vibes.forEach((v, index) => {
                    if (v.id === vibe.id) {
                        // tslint:disable-next-line:radix
                        v.percentage = parseInt(val2);
                    }
                });
                $event.target.value = `${val2}`;
                this.changeDetectorRef.markForCheck();
            } else {
                this.showSaveBar = true;
                this.vibLimitExceed = false;
            }

        }
    }

    // Set value of selected VIBE by entering value in input field
    maxVbValueCheck(event: any, vibe: Vibe) {

        // Check if number key is pressed
        const isNumber = /^[0-9]$/i.test(event.key) || event.key === 'Backspace';

        if (isNumber) {
            this.showSaveBar = true;
            if (+event.target.value > 100) {
                if (this.responsiveWidth > 768) {
                    this.showAlertError('Oops! You are not allowed to Enter value greater then 100');
                }
            }

            this.currentBrandInfo.vibes.forEach((vb, index) => {
                if (vb.id === vibe.id) {
                    vb.percentage = +event.target.value;
                    vibe.percentage = +event.target.value;
                }
            });

        }

    }

    // <===================================================Value================================================================>
    updateValue() {
        return new Promise((resolve, reject) => {
            const updatedValues = [];

            this.currentBrandInfo.values.forEach((value, index) => {
                const uv = new BrandValue(value.id, value.percentage);
                updatedValues.push(uv._obj());
            });

            const brandDetails = {
                brandsignupchecklist: {
                    selected_brand_values: true,
                },
                values: updatedValues,
            };

            this.brandService
                .UpdateBrandById(this.currentBrandInfo.id, brandDetails)
                .subscribe(
                    (brand) => {
                        this.authService.updateCurrentUserBrand(brand);
                        this.initBrandInfo();
                        resolve(brand);
                    },
                    (err) => {
                        this.showAlertError(
                            'Something went wrong while updating the brand info!'
                        );
                        reject(false);
                    }
                );
        });
    }

    addCustomValueTags() {
        this.valueTxt = '';
        const customValueTag = document.getElementById(
            `valueTags`
        ) as HTMLInputElement;
        const customValueTagsArray: Array<string> = customValueTag.value.split(',');
        if (customValueTag.value === null || customValueTag.value === ' ') {
            this.showAlertError('Value Tags Empty!');
        } else {
            customValueTagsArray.forEach((customTag, index) => {
                let exists = false;
                customTag = customTag.trim();
                this.allValues.forEach((value, index2) => {
                    if (customTag !== '') {
                        if (value.title === customTag) {
                            exists = true;
                            const bv = new BrandValue(value.id);
                            this.currentBrandInfo.values.push(bv);
                        }
                    }
                });

                if (!exists && customTag !== '') {
                    const data = {
                        title: customTag,
                    };
                    this.valueService.CreateValue(data).subscribe((value) => {
                        const bv = new BrandValue(value.id);
                        this.currentBrandInfo.values.push(bv);

                        this.updateValue();
                    });
                } else {
                    this.updateValue();
                }
            });
            customValueTag.value = '';
        }
    }

    valueSelected(value: Value) {
        for (const selectedValue of this.currentBrandInfo.values) {
            if (selectedValue.id === value.id) {
                return true;
            }
        }
        return false;
    }

    removeSelectedValue(value: Value) {
        this.currentBrandInfo.values = this.currentBrandInfo.values.filter(
            (obj) => obj.id !== value.id
        );
    }

    toggleBrandValue(value: Value) {
        this.showSaveBar = true;
        const alreadySelected: boolean = this.valueSelected(value);

        const index = this.autoVibes.indexOf(value);
        if (index > -1) {
            this.autoVibes.splice(index, 1);
        } else {
            this.autoVibes.push(value);
        }

        if (alreadySelected) {
            this.removeSelectedValue(value);

            if (
                this.currentBrandInfo.values === undefined ||
                this.currentBrandInfo.values.length === 0
            ) {
                this.currentBrandInfo.values = [];
            }
        } else {
            const bv = new BrandValue(value.id);
            this.currentBrandInfo.values.push(bv);
        }

        if (+this.currentBrandInfo.getValuesCount(this.allValues) < 1) {
            this.autoSetValues = true;
        }

        if (this.autoSetValues) {
            this.setAutoValuePercentage();
        }

        this.updateValue();
    }

    setAutoValuePercentage() {
        const selNumber = this.currentBrandInfo.getValuesCount(
            this.allValues,
            true
        );
        let currentSet = [];
        currentSet = this.getSliderValuesSet(selNumber);
        this.allVibes.forEach((value, index2) => {
            if (value.is_predefined) {
                this.currentBrandInfo.values.forEach((element, index) => {
                    if (value.id === element.id) {
                        // tslint:disable-next-line:radix
                        element.percentage = parseInt(currentSet.shift());
                    }
                });
            }
        });
    }

    submitBrandValues() {
        if (this.currentBrandInfo.getValuesCount(this.allValues) > 0) {
            if (this.totalBrandValuesPer === 100) {
                this.updateValue()
                    .then((resp) => {
                        this.showAlert('Values Updated!');
                        // this.showSaveBar = false;
                        this.initBrandInfo();
                        // setTimeout(() => {
                        //     window.location.reload();
                        // }, 5000);
                        return false;
                    })
                    .catch((err) => {
                        this.showAlertError('Something went wrong!');
                        return false;
                    });
            } else if (this.totalBrandValuesPer > 100) {
                this.showAlertError(
                    'Whoops! You Have Gone Too far. You are allowed to allocate maximum of 100%'
                );
            } else {
                this.showAlertError('Whoops! Allocate 100% To Proceed!');
            }
        } else {
            this.showAlertError(
                'Values are important to let consumers know what you’re workin’ with! Please select at least one!'
            );
        }
    }

    removeValuesTags(tagid) {
        if (this.currentBrandInfo.values.length !== 1) {
            const index: number = this.currentBrandInfo.values.indexOf(
                this.currentBrandInfo.values.find((x) => x.id === tagid)
            );
            if (tagid !== -1) {
                this.currentBrandInfo.values.splice(index, 1);
            }
            this.updateValue();
        } else {
            this.showAlertError('Minimum one value is required!');
        }
    }

    // <=================================================Brand Values Chart & Sliders================================================>
    sumOfBrandValuesPercentages() {
        this.totalBrandValuesPer = 0;
        this.currentBrandInfo.values.forEach((brandValue, index) => {
            // @ts-ignore
            // tslint:disable-next-line:radix
            this.totalBrandValuesPer += parseFloat(brandValue.percentage);
        });

        return this.totalBrandValuesPer;
    }

    getBrandValuesChartData() {
        // tslint:disable-next-line:prefer-for-of
        if (this.sumOfBrandValuesPercentages() === 0) {
            return ['100'];
        }
        this.brandvalsArr = [];
        if (Array.isArray(this.allValues) && this.allValues.length) {
            this.allValues.forEach((brandValue, index) => {
                this.brandvalsArr.push(brandValue.percentage);
            });
        }
        return this.brandvalsArr;
    }

    getBrandValuesChartsColors() {
        if (this.sumOfBrandValuesPercentages() === 0) {
            return [{backgroundColor: ['#f0f0f0']}];
        }
        const brandValuesColors = [];
        if (Array.isArray(this.allValues) && this.allValues.length) {
            this.allValues.forEach((brandValue, index) => {
                if (brandValue.is_predefined) {
                    brandValuesColors.push(brandValue.color);
                }
            });
        }
        return [{backgroundColor: brandValuesColors}];
    }

    getBrandValuesChartsLabels() {
        if (this.sumOfBrandValuesPercentages() === 0) {
            return ['Unallocated'];
        }
        const brandValueArr = [];
        if (Array.isArray(this.allValues) && this.allValues.length) {
            this.allValues.forEach((brandValue, index) => {
                if (brandValue.is_predefined) {
                    brandValueArr.push(brandValue.title);
                }
            });
        }
        return brandValueArr;
    }

    onBrandValuesSliderChange($event, brandValue: Value) {
        let val = $event.target.value;
        this.autoSetValues = false;
        const alreadySelected: boolean = this.valueSelected(brandValue);

        if (val > 100) {
            this.showAlertError(
                'Oops! You are not allowed to Enter value greater then 100'
            );
        } else if (val < 1) {

            const index = this.autoValues.indexOf(brandValue);
            if (index > -1) {
                this.autoValues.splice(index, 1);
            } else {
                this.autoValues.push(brandValue);
            }

            if (alreadySelected) {
                this.removeSelectedValue(brandValue);

                if (this.currentBrandInfo.values === undefined || this.currentBrandInfo.values.length === 0) {
                    this.currentBrandInfo.values = [];
                }

                this.updateValue().then(() => {
                    this.setAutoValuePercentage();
                    this.initBrandInfo();
                });
            }

        } else {

            if (!alreadySelected) {
                const bv = new BrandValue(brandValue.id);
                this.currentBrandInfo.values.push(bv);
                this.updateValue().then(() => {
                    this.initBrandInfo();
                });
                this.changeDetectorRef.markForCheck();
            }

            // tslint:disable-next-line:radix
            brandValue.percentage = parseInt(val);
            this.currentBrandInfo.values.forEach((bv, index) => {
                if (bv.id === brandValue.id) {
                    // tslint:disable-next-line:radix
                    bv.percentage = parseInt(val);
                }
            });

            const currTotal = this.sumOfBrandValuesPercentages();

            if (currTotal > 100) {
                const diff = currTotal - 100;
                val = val - diff;
                this.valLimitExceed = true;
                // tslint:disable-next-line:radix
                brandValue.percentage = parseInt(val);
                this.currentBrandInfo.values.forEach((bv, index) => {
                    if (bv.id === brandValue.id) {
                        // tslint:disable-next-line:radix
                        bv.percentage = parseInt(val);
                    }
                });
                // document.getElementById()
                $event.target.value = `${val}`;
                this.changeDetectorRef.markForCheck();
            } else {
                this.showSaveBar = true;
                this.valLimitExceed = false;
            }

        }
    }

    // Set value of selected VALUE by entering value in input field
    maxVlValueCheck(event: any, value: Value) {

        // Check if number key is pressed
        const isNumber = /^[0-9]$/i.test(event.key) || event.key === 'Backspace';

        if (isNumber) {
            this.showSaveBar = true;
            if (+event.target.value > 100) {
                if (this.responsiveWidth > 768) {
                    this.showAlertError('Oops! You are not allowed to Enter value greater then 100');
                }
            }

            this.currentBrandInfo.values.forEach((vl, index) => {
                if (vl.id === value.id) {
                    vl.percentage = +event.target.value;
                    value.percentage = +event.target.value;
                }
            });

        }

    }

    // <===================================================Social Causes================================================================>
    updateCauses() {
        const updatedSocialCauses = [];

        this.currentBrandInfo.social_causes.forEach((value, index) => {
            const uv = new BrandSocialCause(value.id);
            updatedSocialCauses.push(uv._obj());
        });

        const brandDetails = {
            brandsignupchecklist: {
                selected_social_values: true,
            },
            social_causes: updatedSocialCauses,
        };

        this.brandService
            .UpdateBrandById(this.currentBrandInfo.id, brandDetails)
            .subscribe(
                (brand) => {
                    this.authService.updateCurrentUserBrand(brand);
                    this.initBrandInfo();
                },
                (err) => {
                    this.showAlertError(
                        'Something went wrong while updating the brand info!'
                    );
                }
            );
    }

    addCustomSocialTags() {
        this.scsTxt = '';
        const customSocialTag = document.getElementById(
            `socialTags`
        ) as HTMLInputElement;
        const customSocialTagsArray: Array<string> = customSocialTag.value.split(
            ','
        );
        if (customSocialTag.value === null || customSocialTag.value === '') {
            this.showAlertError('social Tags Empty!');
        } else {
            customSocialTagsArray.forEach((customTag, index) => {
                let exists = false;
                customTag = customTag.trim();
                this.allSocialCauses.forEach((socialCause, index2) => {
                    if (customTag !== '') {
                        if (socialCause.title === customTag) {
                            exists = true;
                            const bsc = new BrandSocialCause(socialCause.id);
                            this.currentBrandInfo.social_causes.push(bsc);
                        }
                    }
                });

                if (!exists && customTag !== '') {
                    const data = {
                        title: customTag,
                    };
                    this.socialCauseService
                        .CreateSocialCause(data)
                        .subscribe((socialCause) => {
                            const bsc = new BrandSocialCause(socialCause.id);
                            this.currentBrandInfo.social_causes.push(bsc);

                            this.updateCauses();
                        });
                } else {
                    this.updateCauses();
                }
            });
            customSocialTag.value = '';
        }
    }

    socialCauseSelected(socialCause: SocialCause) {
        for (const selectedSocialCause of this.currentBrandInfo.social_causes) {
            if (selectedSocialCause.id === socialCause.id) {
                return true;
            }
        }
        return false;
    }

    removeSelectedSocialCause(socialCause: SocialCause) {
        this.currentBrandInfo.social_causes = this.currentBrandInfo.social_causes.filter(
            (obj) => obj.id !== socialCause.id
        );
    }

    toggleBrandSocialCause(socialCause: SocialCause) {
        this.showSaveBar = true;
        const alreadySelected: boolean = this.socialCauseSelected(socialCause);

        if (alreadySelected) {
            this.removeSelectedSocialCause(socialCause);

            if (
                this.currentBrandInfo.social_causes === undefined ||
                this.currentBrandInfo.social_causes.length === 0
            ) {
                this.currentBrandInfo.social_causes = [];
            }
        } else {
            const bsc = new BrandSocialCause(socialCause.id);
            this.currentBrandInfo.social_causes.push(bsc);
        }

        const updatedSocialCauses = [];

        this.currentBrandInfo.social_causes.forEach((sc, index) => {
            const ubsc = new BrandSocialCause(sc.id);
            updatedSocialCauses.push(ubsc._obj());
        });

        const brandDetails = {
            brandsignupchecklist: {
                selected_social_values: true,
            },
            social_causes: updatedSocialCauses,
        };

        this.brandService
            .UpdateBrandById(this.currentBrandInfo.id, brandDetails)
            .subscribe(
                (brand) => {
                    this.authService.updateCurrentUserBrand(brand);
                },
                (err) => {
                    this.showAlertError(
                        'Something went wrong while updating the brand info!'
                    );
                }
            );
    }

    submitBrandSocialCauses() {
        if (this.currentBrandInfo.social_causes.length > 0) {
            this.showAlert('Social Causes Updated!');
            // this.showSaveBar = false;
            // setTimeout(() => {
            //     window.location.reload();
            // }, 5000);
            return false;
        }
    }

    removeSocialCausesTags(tagid) {
        if (this.currentBrandInfo.social_causes.length !== 1) {
            const index: number = this.currentBrandInfo.social_causes.indexOf(
                this.currentBrandInfo.social_causes.find((x) => x.id === tagid)
            );
            if (tagid !== -1) {
                this.currentBrandInfo.social_causes.splice(index, 1);
            }
            this.updateCauses();
        } else {
            this.showAlertError('Minimum one social cause is required!');
        }
    }

    toggleOtherSocialCauseShow() {
        this.showSaveBar = true;
        this.isOtherSocialCauseShown = !this.isOtherSocialCauseShown;
    }

    addCustomSocialOthers(e) {
        const customSocialOther = document.getElementById(
            `socialCauseother`
        ) as HTMLInputElement;
        const customSocialOtherArray: Array<string> = customSocialOther.value.split(
            ','
        );
        if (customSocialOther.value === null || customSocialOther.value === '') {
        } else {
            customSocialOtherArray.forEach((customOther, index) => {
                let exists = false;
                this.allSocialCauses.forEach((socialCause, index2) => {
                    if (socialCause.title === customOther) {
                        exists = true;
                        const bsc = new BrandSocialCause(socialCause.id);
                        this.currentBrandInfo.social_causes.push(bsc);
                    }
                });

                if (!exists) {
                    const data = {
                        title: customOther,
                        is_other: true,
                    };
                    this.socialCauseService
                        .CreateSocialCause(data)
                        .subscribe((socialCause) => {
                            const bsc = new BrandSocialCause(socialCause.id);
                            this.currentBrandInfo.social_causes.push(bsc);

                            this.updateCauses();
                            customSocialOther.value = '';
                        });
                } else {
                    this.updateCauses();
                    customSocialOther.value = '';
                }
            });
        }
    }

    getSliderValuesSet(selNumber) {
        const slidersSplitValues = [
            [100],
            [50, 50],
            [35, 35, 30],
            [25, 25, 25, 25],
            [20, 20, 20, 20, 20],
            [20, 20, 15, 15, 15, 15],
            [20, 20, 20, 10, 10, 10, 10],
            [20, 20, 10, 10, 10, 10, 10, 10],
            [20, 10, 10, 10, 10, 10, 10, 10, 10],
        ];
        switch (selNumber) {
            case 1:
                return slidersSplitValues[0];

            case 2:
                return slidersSplitValues[1];

            case 3:
                return slidersSplitValues[2];

            case 4:
                return slidersSplitValues[3];

            case 5:
                return slidersSplitValues[4];

            case 6:
                return slidersSplitValues[5];

            case 7:
                return slidersSplitValues[6];

            case 8:
                return slidersSplitValues[7];

            case 9:
                return slidersSplitValues[8];
        }
    }

    showTabContentDropdown(tabContentID, fragment) {
        const links = document.getElementsByClassName('bs-tab-link');
        const tabPanes = document.getElementsByClassName('bs-tab-content');

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < tabPanes.length; i++) {
            tabPanes[i].classList.remove('show', 'active');
        }
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < links.length; j++) {
            links[j].classList.remove('active');
        }

        document.getElementById(tabContentID).classList.add('show', 'active');
    }

    showTabContent(pop: HTMLDivElement, link: HTMLAnchorElement) {
        this.showSaveBar = false;

        const links = document.getElementsByClassName('bs-tab-link');
        const tabPanes = document.getElementsByClassName('bs-tab-content');

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < tabPanes.length; i++) {
            tabPanes[i].classList.remove('show', 'active');
        }
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < links.length; j++) {
            links[j].classList.remove('active');
        }
        pop.classList.add('show', 'active');
        link.classList.add('active');

        this.changeDetector();
    }

    tagInputCounter(inputElem: HTMLInputElement, elemId: string) {
        const len = inputElem.value.length;
        const max = 25;
        if (len > max) {
            inputElem.value = inputElem.value.substring(0, max);
        } else {
            const remainingCount = max - len;
            document.getElementById(elemId).innerText = `${remainingCount}/${max}`;
        }
    }

    setProfilePic(e: any, elemId: string) {
        const file = e.target.files[0];
        const imgElem = document.getElementById(elemId) as HTMLImageElement;

        if (file) {
            const reader = new FileReader();
            this.updateProfilePicFlag = true;
            reader.onload = () => {
                imgElem.src = reader.result as string;
            };
            reader.readAsDataURL(file);
            this.showSaveBar = true;
        }
    }

    toggleSaveBar() {
        this.showSaveBar = true;
    }

    showAlert(message: string) {
        this.showAlertErrorMassage = false;
        clearTimeout(this.alertTimeout);
        clearTimeout(this.alertErrorTimeout);
        this.showAlertMessage = true;
        this.alertMessage = message;
        this.alertTimeout = setTimeout(() => {
            this.showAlertMessage = false;
            this.alertMessage = '';
        }, 5000);
    }

    showAlertError(message: string) {
        this.showAlertMessage = false;
        clearTimeout(this.alertTimeout);
        clearTimeout(this.alertErrorTimeout);
        this.showAlertErrorMassage = true;
        this.alertMessage = message;
        this.alertErrorTimeout = setTimeout(() => {
            this.showAlertErrorMassage = false;
            this.alertMessage = '';
        }, 5000);
    }

    hideAlert() {
        this.showAlertMessage = false;
        this.showAlertErrorMassage = false;
        this.alertMessage = '';
    }

    onKeyUpCategory(event, pid) {
        if (event.key === ',' || event.key === 'Enter') {
            const minPrice = document.getElementById(
                `min-price-${pid}`
            ) as HTMLInputElement;
            const maxPrice = document.getElementById(
                `max-price-${pid}`
            ) as HTMLInputElement;
            const avgPrice = document.getElementById(
                `avg-price-${pid}`
            ) as HTMLInputElement;
            let customTagCategoriesArray: Array<string> = event.target.value.split(
                ','
            );
            customTagCategoriesArray.forEach((tag, index) => {
                customTagCategoriesArray[index] = tag.trim();
            });
            customTagCategoriesArray = customTagCategoriesArray.filter((e) => e);
            if (event.target.value === null || event.target.value === '') {
                this.showAlertError('Tags Empty!');
            } else {
                // tslint:disable-next-line:only-arrow-functions
                const uniqueTags = customTagCategoriesArray.filter(function(
                    elem,
                    index,
                    self
                ) {
                    return index === self.indexOf(elem);
                });

                this.currentBrandInfo.product_categories.forEach(
                    (productCategory, index2) => {
                        if (productCategory.id === pid) {
                            if (minPrice !== null && maxPrice !== null && avgPrice !== null) {
                                // tslint:disable-next-line:radix
                                productCategory.min_price = parseInt(minPrice.value);
                                // tslint:disable-next-line:radix
                                productCategory.max_price = parseInt(maxPrice.value);
                                // tslint:disable-next-line:radix
                                productCategory.avg_price = parseInt(avgPrice.value);
                                if (productCategory.tags != null) {
                                    // tslint:disable-next-line:prefer-for-of
                                    for (let i = 0; i < productCategory.tags.length; i++) {
                                        uniqueTags.push(productCategory.tags[i]);
                                    }
                                }
                                productCategory.tags = uniqueTags.filter(
                                    (elem, index, self) => index === self.indexOf(elem)
                                );
                                this.initBrandInfo();
                            } else {
                                this.showAlertError(
                                    'Price range for product category is required'
                                );
                            }
                        }
                    }
                );
                this.updateBrandProductCategories();
            }
        }
    }

    onKeyUpVibe(event) {
        if (event.key === ',') {
            const customVibeTagsArray: Array<string> = event.target.value.split(',');
            if (event.target.value === null || event.target.value === '') {
                this.showAlertError('Vibes Tags Empty!');
            } else {

                customVibeTagsArray.forEach((customTag, index) => {
                    let exists = false;
                    customTag = customTag.trim();
                    this.allVibes.forEach((vibe, index2) => {
                        if (customTag !== '') {
                            if (vibe.title === customTag) {
                                exists = true;
                                const bv = new BrandVibe(vibe.id);
                                this.currentBrandInfo.vibes.push(bv);
                            }
                        }
                    });

                    if (!exists && customTag !== '') {
                        const data = {
                            title: customTag,
                        };
                        this.vibeService.CreateVibes(data).subscribe((vibe) => {
                            const bv = new BrandVibe(vibe.id);
                            this.currentBrandInfo.vibes.push(bv);
                            this.updateVibe();
                        });
                    } else {
                        this.updateVibe();
                    }
                });
                event.target.value = '';
            }
        }
    }

    onKeyUpValue(event) {
        if (event.key === ',') {
            const customValueTagsArray: Array<string> = event.target.value.split(',');
            if (event.target.value === null || event.target.value === ' ') {
                this.showAlertError('Value Tags Empty!');
            } else {
                customValueTagsArray.forEach((customTag, index) => {
                    let exists = false;
                    customTag = customTag.trim();
                    this.allValues.forEach((value, index2) => {
                        if (customTag !== '') {
                            if (value.title === customTag) {
                                exists = true;
                                const bv = new BrandValue(value.id);
                                this.currentBrandInfo.values.push(bv);
                            }
                        }
                    });

                    if (!exists && customTag !== '') {
                        const data = {
                            title: customTag,
                        };
                        this.valueService.CreateValue(data).subscribe((value) => {
                            const bv = new BrandValue(value.id);
                            this.currentBrandInfo.values.push(bv);

                            this.updateValue();
                        });
                    } else {
                        this.updateValue();
                    }
                });
                event.target.value = '';
            }
        }
    }

    onKeyUpSocial(event) {
        if (event.key === ',') {
            const customSocialTagsArray: Array<string> = event.target.value.split(
                ','
            );
            if (event.target.value === null || event.target.value === '') {
                this.showAlertError('social Tags Empty!');
            } else {
                customSocialTagsArray.forEach((customTag, index) => {
                    let exists = false;
                    customTag = customTag.trim();
                    this.allSocialCauses.forEach((socialCause, index2) => {
                        if (customTag !== '') {
                            if (socialCause.title === customTag) {
                                exists = true;
                                const bsc = new BrandSocialCause(socialCause.id);
                                this.currentBrandInfo.social_causes.push(bsc);
                            }
                        }
                    });

                    if (!exists && customTag !== '') {
                        const data = {
                            title: customTag,
                        };
                        this.socialCauseService
                            .CreateSocialCause(data)
                            .subscribe((socialCause) => {
                                const bsc = new BrandSocialCause(socialCause.id);
                                this.currentBrandInfo.social_causes.push(bsc);

                                this.updateCauses();
                            });
                    } else {
                        this.updateCauses();
                    }
                });
                event.target.value = '';
            }
        }
    }

    revealSaveBar() {
        this.showSaveBar = true;
    }

    ageRequirementSelection(selection: number) {
        if (this.ageRequirementSelected === selection) {
            this.ageRequirementSelected = -1;
            this.ageRequirement = null;
        } else {
            this.ageRequirementSelected = selection;
            if (selection === 1) {
                this.ageRequirement = null;
            }
        }
        this.showSaveBar = true;
    }
}
